import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import DataGridCell from 'components/common/DataGridCell';
import FileUploader from 'components/common/file-uploader';
import SectionTitle from 'components/common/SectionTitle';
import { currencyFormatter } from 'helpers/currency-formatter';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { transportDocumentService } from 'services/api';
import { booleanFilterOperators, filterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';

export function TransportDocumentColumns(onUploadSuccess): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'ref',
      headerName: t(`entities.ddt.ref`),
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <NavLink to={`./${params.row?.id}`}>
          <SectionTitle>{params.value}</SectionTitle>
        </NavLink>
      )
    },
    {
      field: 'closed',
      headerName: t(`entities.ddt.closed`),
      width: 100,
      filterOperators: booleanFilterOperators,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.row.closed ? <LockIcon /> : null;
      }
    },
    {
      field: 'date',
      headerName: t(`entities.ddt.date`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'invoice_date',
      headerName: t(`entities.ddt.invoice_date`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    }
  ];

  ['amount', 'expected_amount', 'shipping_fare'].forEach((colName) => {
    columns.push({
      field: colName,
      headerName: t(`entities.ddt.${colName}`),
      filterOperators,
      type: 'number',
      width: 150,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={currencyFormatter(params.value)} />
      )
    });
  });

  ['invoice_file', 'ddt_file', 'invoice_revision_file'].forEach((file) => {
    columns.push({
      field: file,
      headerName: t(`entities.ddt.${file}`),
      filterOperators,
      width: 150,
      renderCell: (params: GridRenderCellParams<boolean>) => (
        <FileUploader
          onUploadSuccess={onUploadSuccess}
          service={transportDocumentService}
          entityId={params.row?.id}
          attachmentName={file}
          canDownload={params.value}
        />
      )
    });
  });

  return columns;
}
