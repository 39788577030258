import { ITenantSettings } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class TenantSettingsService extends BaseService<ITenantSettings> {
  constructor() {
    super('tenant-settings');
  }

  attachFiles = async (attachment: File, attachmentName: string) => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/attach-files/`, {
      [attachmentName]: attachment
    });
    return res.data;
  };

  downloadAttachment = async (_: number, attachmentName: string) => {
    axiosPrivate
      .get(`${this.modulePrefix}/download-attachment/${attachmentName}/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-tenant-tree-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const tenantSettingsService = new TenantSettingsService();
