import { Typography } from '@mui/material';

function CountersWidget() {
  return (
    <div className="bg-primary-200 flex w-full p-3 rounded-md">
      {[
        { value: 132, label: 'Movimenti', description: '-21% rispetto al giorno precedente' },
        { value: 325, label: 'Ordini', description: '+12% rispetto al giorno precedente' },
        {
          value: 5,
          label: 'Dispositivi connessi',
          description: '5 dispositivi Zebra attivi'
        }
      ].map((x, i) => (
        <div
          key={i}
          className="w-1/3 p-5 flex flex-col justify-center items-center border-r border-primary-300 last:border-r-0">
          <Typography variant="h4" fontWeight={800} color="primary">
            {x.value}
          </Typography>
          <Typography variant="body1" fontWeight={500}>
            {x.label}
          </Typography>
          <Typography variant="caption">{x.description}</Typography>
        </div>
      ))}
    </div>
  );
}

export default CountersWidget;
