import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { stockQuantityService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';

export const StockQuantitiesDetail = () => {
  const translationPrefix = 'pages.stock_quantity.detail';

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const columns: GridColDef[] = StockQuantitiesColumns();

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}>
        <EnhancedDataGrid
          service={stockQuantityService}
          columns={columns}
          initialParams={{
            product: searchParams.get('product'),
            warehouse: searchParams.get('warehouse')
          }}
          initialOptions={{
            pinnedColumns: {
              left: ['product_code'],
              right: ['total_quantity', 'actions']
            }
          }}
        />
      </TenantPage>
    </>
  );
};

export default StockQuantitiesDetail;
