import { Accordion, AccordionSummary, Alert, Button, ButtonGroup, IconButton } from '@mui/material';
import { TenantPage } from 'components/common';
import { MovementsColumns } from 'components/common/enhanced/common-headers/movements';
import SectionTitle from 'components/common/SectionTitle';
import { IMovement, ITransportDocument } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { movementService, transportDocumentService } from 'services/api';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGridPro, itIT } from '@mui/x-data-grid-pro';
import { currencyFormatter } from 'helpers/currency-formatter';
import FileUploader from 'components/common/file-uploader';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { Field } from 'react-final-form';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import toast from 'features/toast';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';

export function TransportDocumentDetail() {
  const { t } = useTranslation();
  const prefix = 'pages.ddt.detail';
  const columns = MovementsColumns([]);
  const { id } = useParams();

  const [ddt, setDdt] = useState<ITransportDocument>();
  const [movements, setMovements] = useState<IMovement[]>([]);

  useEffect(() => {
    transportDocumentService.get(+id).then(setDdt);
  }, []);

  useEffect(() => {
    if (ddt) {
      movementService
        .getAllPaginated(10000, 0, {
          transport_document: ddt.ref
        })
        .then((res) => setMovements(res.results));
    }
  }, [ddt]);

  const downloadableFields = [
    'invoice_file',
    'ddt_file',
    'invoice_revision_file',
    'extra_1_file',
    'extra_2_file',
    'extra_3_file'
  ];

  const updateDDT = (values) => {
    transportDocumentService.update(ddt.id, values).then((res) => {
      setDdt(res);
      toast.success('DDT aggiornato con successo');
    });
  };

  const downloadCreditMemo = () => {
    transportDocumentService.downloadCreditMemo(ddt.id);
  };

  const downloadInvoice = () => {
    transportDocumentService.downloadInvoiceAgreement(ddt.id);
  };
  const sendCreditMemo = () => {
    transportDocumentService.emailCreditMemo(ddt.id);
  };

  const sendInvoice = () => {
    transportDocumentService.emailInvoiceAgreement(ddt.id);
  };

  return (
    <TenantPage
      title={t(`${prefix}.title`) + ' ' + ddt?.ref}
      subtitle={t(`${prefix}.subtitle`)}
      menuRight={
        <div className="flex flex-wrap">
          {ddt && +ddt.amount - +ddt.shipping_fare > +ddt.expected_amount && (
            <ButtonGroup size="small" className="mr-4">
              <Button sx={{ pointerEvents: 'none' }}>{t(`${prefix}.credit-note`)}</Button>
              <Button onClick={downloadCreditMemo}>
                <DownloadIcon />
              </Button>
              <Button onClick={sendCreditMemo}>
                <SendIcon />
              </Button>
            </ButtonGroup>
          )}
          <ButtonGroup size="small">
            <Button sx={{ pointerEvents: 'none' }}>{t(`${prefix}.invoice-agreement`)}</Button>
            <Button onClick={downloadInvoice}>
              <DownloadIcon />
            </Button>
            <Button onClick={sendInvoice}>
              <SendIcon />
            </Button>
          </ButtonGroup>
        </div>
      }>
      <div>
        <div className="w-full">
          {ddt && ddt.amount != null && +ddt.amount !== +ddt.expected_amount && (
            <Alert severity="warning" className="w-full">
              {t(`${prefix}.incoerent-amounts`)}
            </Alert>
          )}
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.header`)}</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-3/4 pr-8">
              {ddt && (
                <ReactFinalForm onSubmit={updateDDT} hideControls={true} formId="amountForm">
                  <div className="flex items-center">
                    <div className="mr-4">
                      <Field
                        name="amount"
                        label={t(`entities.ddt.amount`)}
                        initialValue={ddt.amount}
                        component={TextFieldAdapter}
                        type="number"
                      />
                    </div>
                    <div className="mr-4">
                      <Field
                        name="shipping_fare"
                        label={t(`entities.ddt.shipping_fare`)}
                        initialValue={ddt.shipping_fare}
                        component={TextFieldAdapter}
                        type="number"
                      />
                    </div>
                    <div className="mr-4">
                      <Field
                        name="invoice_ref"
                        label={t(`entities.ddt.invoice_ref`)}
                        initialValue={ddt.invoice_ref}
                        component={TextFieldAdapter}
                        type="text"
                      />
                    </div>
                    <div className="mr-4">
                      <Field
                        name="invoice_date"
                        label={t(`entities.ddt.invoice_date`)}
                        component={DatePickerAdapter}
                        type="date"
                        initialValue={ddt?.invoice_date ?? null}
                      />
                    </div>
                    <div className="mr-4">
                      <Field
                        name="closed"
                        label={ddt?.closed ? t(`entities.ddt.closed`) : t(`entities.ddt.open`)}
                        component={CheckboxAdapter}
                        type="checkbox"
                        initialValue={ddt?.closed ?? false}
                        icon={<LockOpenOutlinedIcon />}
                        checkedIcon={<LockIcon />}
                      />
                    </div>
                    <IconButton type="submit" form="amountForm" color="primary">
                      <SaveIcon />
                    </IconButton>
                  </div>
                </ReactFinalForm>
              )}
            </div>
            <div className="w-1/4">
              {ddt && (
                <div>
                  <b>{t(`${prefix}.invoice-expected`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(ddt.expected_amount)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.files`)}</SectionTitle>
          {ddt && (
            <div className="flex flex-wrap">
              {downloadableFields.map((field, i) => (
                <div key={i} className={`w-1/${downloadableFields.length}`}>
                  <span>{t(`entities.ddt.${field}`)}:</span>
                  <FileUploader
                    service={transportDocumentService}
                    entityId={ddt.id}
                    attachmentName={field}
                    canDownload={ddt[field]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.order-list`)}</SectionTitle>
          {ddt &&
            ddt.orders.map((order) => (
              <Accordion key={order.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography>
                    <b>{order.ref}</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="flex flex-wrap">
                      <div className="mr-4">
                        {t('global.status')}: <b>{order.status}</b>
                      </div>
                      <div className="mr-4">
                        {t('global.supplier')}: <b>{order.supplier}</b>
                      </div>
                      <div className="mr-4">
                        {t('global.warehouse')}: <b>{order.warehouse}</b>
                      </div>
                      <div className="mr-4">
                        {t('entities.order.from_request')}: <b>{order.from_request}</b>
                      </div>
                      <div className="mr-4">
                        {t('entities.order.created_at')}:{' '}
                        <b>{dayjs(order.created_at).format('YYYY-MM-DD HH:mm')}</b>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
        {ddt && (
          <>
            <SectionTitle>{t(`${prefix}.movement-list`)}</SectionTitle>
            <DataGridPro
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              autoHeight
              columns={columns}
              rows={movements}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              pageSize={5}
              initialState={{
                pinnedColumns: {
                  left: ['direction', 'moved_quantity'],
                  right: ['actions']
                }
              }}
            />
          </>
        )}
      </div>
    </TenantPage>
  );
}

export default TransportDocumentDetail;
