import { GridColumns } from '@mui/x-data-grid-pro';
import { TenantPage } from 'components/common';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { imgCell } from 'components/common/enhanced/cells/img-cell';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useTranslation } from 'react-i18next';
import { courierService } from 'services/api';
import { mock_couriers } from '../mocks';

function CourierList() {
  const { t } = useTranslation();
  const _couriers = mock_couriers;

  const _columns: GridColumns = [
    {
      field: 'name',
      headerName: t(`global.description`),
      width: 350,
      renderCell: baseCell
    },
    {
      field: 'code',
      headerName: '',
      width: 150,
      renderCell: imgCell('/couriers', '.png')
    }
  ];

  return (
    <TenantPage title={t('menu.couriers')}>
      <div>
        <EnhancedDataGrid columns={_columns} service={courierService} />
      </div>
    </TenantPage>
  );
}

export default CourierList;
