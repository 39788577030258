import { ButtonGroup } from '@mui/material';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { MovementsColumns } from 'components/common/enhanced/common-headers/movements';
import { movementReasonService, movementService } from 'services/api';
import { useEffect, useState } from 'react';
import { IOption } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';

export const UninvoicedMovements = () => {
  const translationPrefix = 'pages.movements.uninvoiced';
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
    dispatch(loadingActions.stopLoading());
  }, []);

  const columns: GridColDef[] = MovementsColumns(
    movementReasons.map((reason) => reason.code),
    true,
    false
  );

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div>
          <ButtonGroup size="small"></ButtonGroup>
        </div>
      }>
      <EnhancedDataGrid
        columns={columns}
        service={movementService}
        initialParams={{ ddt_closed: 'false' }}
      />
    </TenantPage>
  );
};

export default UninvoicedMovements;
