import { loadingActions } from 'features';
import { typeService } from 'services/api';
import { useAppDispatch } from 'app/store';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import TypeForm from '../TypeForm';
import toast from 'features/toast';

const initType = {
  id: null,
  code: null,
  description: null
};

export const CreateType = () => {
  const translationPrefix = 'types.pages.new';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createType = async (values) => {
    dispatch(loadingActions.startLoading());
    typeService.add(values).then((res) => {
      toast.success('Tipo aggiunto con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <TypeForm type={initType} onSubmit={createType} />
    </TenantPage>
  );
};

export default CreateType;
