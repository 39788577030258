import { currencyFormatter } from 'helpers/currency-formatter';
import { IContractLine } from 'interfaces';

export function contractValue(contractLines: IContractLine[]) {
  if (!contractLines) return 0;
  const tot = contractLines.reduce(
    (tot, line) => (line.unit_price as number) * line.initial_available_units + tot,
    0
  );
  return currencyFormatter(tot);
}

export function contractRealValue(contractLines: IContractLine[]) {
  if (!contractLines) return 0;
  const tot = contractLines.reduce(
    (tot, line) =>
      (line.unit_price as number) *
        (+line.initial_available_units + +line.initial_discounted_units) +
      tot,
    0
  );
  return currencyFormatter(tot);
}
