const API_URL = 'http://localhost:9100/';
const SIGNATURE = '__GLM__';

export async function printLabels(barCode, batch, expiration, quantity = 1) {
  for (let i = 0; i < quantity; i++) {
    printLabel(barCode, batch, expiration);
  }
}

export async function printLabel(code, batch, expiration) {
  const productIdentifier = `${SIGNATURE}${code}${SIGNATURE}`;

  let expiration_date = 'N.D.',
    expiration_date_GS1 = '';

  if (!!expiration && typeof expiration === 'string' && expiration.includes('-')) {
    const splitted_date = expiration.split('-');
    splitted_date[0] = splitted_date[0].slice(2);
    expiration_date = splitted_date.join('');
    expiration_date_GS1 = `17${expiration_date}`;
  }

  const zplString =
    `^XA
        ^BY5,2,270
        ^FO 50, 50^BX,9,200,0,0,1,_,1^FD${productIdentifier}10${batch}${expiration_date_GS1}^FS
        ^CFA,20
        ^FO 280, 80 ^A 1, 500 ^FD Prodotto: ${code} ^FS
        ^FO 280, 130 ^A 1, 500 ^FD Lotto (10): ${batch} ^FS` +
    (expiration ? `^FO 280, 180 ^A 1, 500 ^FD Scadenza (17): ${expiration} ^FS` : ``) +
    `^XZ`;

  try {
    // Create a new instance of the object
    const browserPrint = new ZebraBrowserPrintWrapper();
    // Select default printer
    const defaultPrinter = await browserPrint.getDefaultPrinter();
    // Set the printer
    await browserPrint.setPrinter(defaultPrinter);
    // Check printer status
    let printerStatus = await browserPrint.checkPrinterStatus();
    // Check if the printer is ready
    let retry = 0;
    while (retry !== 3) {
      if (printerStatus.isReadyToPrint || printerStatus.errors === 'Error: Unknown Error') {
        try {
          await browserPrint.print(zplString);
        } catch {
          return false;
        }
        break;
      } else {
        printerStatus = await browserPrint.checkPrinterStatus();
        sleep(500);
        retry += 1;
      }
    }
    if (retry === 3) {
      alert(
        'Zebra Service: problemi con la stampante, si prega di ritentare. Se persiste contattare Sphera.'
      );
      return false;
    }
  } catch (error) {
    alert(
      'Zebra Service: si è verificato un errore, si prega di ritentare. Se persiste contattare Sphera.'
    );
    return false;
  }
  return true;
}

export default class ZebraBrowserPrintWrapper {
  device = {};

  getAvailablePrinters = async () => {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8'
      }
    };

    const endpoint = API_URL + 'available';

    try {
      const res = await fetch(endpoint, config);

      const data = await res.json();

      if (
        data &&
        data !== undefined &&
        data.printer &&
        data.printer !== undefined &&
        data.printer.length > 0
      ) {
        return data.printer;
      }

      return new Error('No printers available');
    } catch (error) {
      throw new Error(error);
    }
  };

  getDefaultPrinter = async () => {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8'
      }
    };

    const endpoint = API_URL + 'default';

    try {
      const res = await fetch(endpoint, config);
      const data = await res.text();

      if (
        data &&
        data !== undefined &&
        typeof data !== 'object' &&
        data.split('\n\t').length === 7
      ) {
        const deviceRaw = data.split('\n\t');

        const name = this.cleanUpString(deviceRaw[1]);
        const deviceType = this.cleanUpString(deviceRaw[2]);
        const connection = this.cleanUpString(deviceRaw[3]);
        const uid = this.cleanUpString(deviceRaw[4]);
        const provider = this.cleanUpString(deviceRaw[5]);
        const manufacturer = this.cleanUpString(deviceRaw[6]);

        return {
          connection,
          deviceType,
          manufacturer,
          name,
          provider,
          uid,
          version: 0
        };
      }

      throw new Error("There's no default printer");
    } catch (error) {
      throw new Error(error);
    }
  };

  setPrinter = (device) => {
    this.device = device;
  };

  getPrinter = () => {
    return this.device;
  };

  cleanUpString = (str) => {
    const arr = str.split(':');
    const result = arr.slice(1).join(':').trim();
    return result;
  };

  checkPrinterStatus = async () => {
    await this.write('~hs');
    const result = await this.read();

    const errors = [];
    let isReadyToPrint = false;

    const isError = result.charAt(70);
    const media = result.charAt(88);
    const head = result.charAt(87);
    const pause = result.charAt(84);

    isReadyToPrint = isError === '0';

    switch (media) {
      case '1':
        errors.push('Paper out');
        break;
      case '2':
        errors.push('Ribbon Out');
        break;
      case '4':
        errors.push('Media Door Open');
        break;
      case '8':
        errors.push('Cutter Fault');
        break;
      default:
        break;
    }

    switch (head) {
      case '1':
        errors.push('Printhead Overheating');
        break;
      case '2':
        errors.push('Motor Overheating');
        break;
      case '4':
        errors.push('Printhead Fault');
        break;
      case '8':
        errors.push('Incorrect Printhead');
        break;
      default:
        break;
    }

    if (pause === '1') errors.push('Printer Paused');

    if (!isReadyToPrint && errors.length === 0) errors.push('Error: Unknown Error');

    return {
      isReadyToPrint,
      errors: errors.join()
    };
  };

  write = async (data) => {
    try {
      const endpoint = API_URL + 'write';

      const myData = {
        device: this.device,
        data
      };

      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: JSON.stringify(myData)
      };

      await fetch(endpoint, config);
    } catch (error) {
      throw new Error(error);
    }
  };

  read = async () => {
    try {
      const endpoint = API_URL + 'read';

      const myData = {
        device: this.device
      };

      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: JSON.stringify(myData)
      };

      const res = await fetch(endpoint, config);
      const data = await res.text();
      return data;
    } catch (error) {
      throw new Error(error);
    }
  };

  print = async (text) => {
    try {
      await this.write(text);
    } catch (error) {
      throw new Error(error);
    }
  };
}
/**
 * Delay for a number of milliseconds
 */
function sleep(delay) {
  const start = new Date().getTime();
  while (new Date().getTime() < start + delay);
}
