import { Tab, Tabs } from '@mui/material';
import { TenantPage } from 'components/common';
import { ITenantSettings } from 'interfaces';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { tenantSettingsService } from 'services/api';

function QuestionList() {
  const [settings, setSettings] = useState<ITenantSettings>();
  const [value, setValue] = useState<number | string>();
  const navigate = useNavigate();

  useEffect(() => {
    tenantSettingsService.get(1).then(setSettings);
  }, []);

  useEffect(() => {
    if (value) {
      navigate(`${value}`);
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(+newValue);
  };
  return (
    <TenantPage title="Analisi Costi" subtitle="">
      <Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
        {settings?.questions.map((question) => (
          <Tab key={question.id} value={question.id} label={question.title}></Tab>
        ))}
      </Tabs>
      <Outlet />
    </TenantPage>
  );
}

export default QuestionList;
