import { IContract, IContractLine } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { ExportableResourceService } from './ExportableResourceService';

class ContractService extends ExportableResourceService<IContract> {
  constructor() {
    super('contracts');
  }

  assignProducts = async (products: IContractLine[], contractId: number) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${contractId}/assign_products/`, {
      lines: products
    });
    return res.data;
  };

  removeProducts = async (products: number[], contractId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${contractId}/delete_lines/`, {
      lines: products
    });
    return res.data;
  };

  getProducts = async (contractId: number) => {
    const res = await axiosPrivate.get(`contract-lines/?contract_header=${contractId}`);
    return res.data;
  };
}

export const contractService = new ContractService();
