import { IPurchaseOrderRow } from 'interfaces';
import { BaseService } from './BaseService';

class OrderLineService extends BaseService<IPurchaseOrderRow> {
  constructor() {
    super('purchase-order-lines');
  }
}

export const orderLineService = new OrderLineService();
