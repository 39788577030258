import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { TenantPage } from 'components/common';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { dateCell } from 'components/common/enhanced/cells/date-cell';
import { imgCell } from 'components/common/enhanced/cells/img-cell';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useTranslation } from 'react-i18next';
import { productService, sellingOrderService } from 'services/api';
import SyncIcon from '@mui/icons-material/Sync';
import toast from 'features/toast';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
function SellingOrderList() {
  const { t } = useTranslation();
  // const _orders = mock_orders;

  const _columns: GridColumns = [];
  [
    {
      label: 'resourcetype',
      tPrefix: 'entities.selling-order',
      width: 150,
      render: imgCell('/stores', '.png')
    },
    // { label: 'code', width: 100 },
    { label: 'purchase_date', tPrefix: 'entities.selling-order', width: 150, render: dateCell },
    { label: 'buyer_name', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'description', width: 250 },
    // { label: 'dimension', tPrefix: 'entities.selling-order', width: 250 },
    // { label: 'earliest_delivery_date', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'earliest_ship_date', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'external_order_id', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'is_gift', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'is_prime', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'latest_delivery_date', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'latest_ship_date', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'marketplace_id', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'num_shipment_packages', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'purchase_date', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'shipment_address_1', tPrefix: 'entities.selling-order', width: 250 },
    // { label: 'shipment_address_2', tPrefix: 'entities.selling-order', width: 250 },
    // { label: 'shipment_address_3', tPrefix: 'entities.selling-order', width: 250 },
    { label: 'shipment_beneficiary', tPrefix: 'entities.selling-order', width: 250 }
    // { label: 'shipment_location', tPrefix: 'entities.selling-order', width: 250 },
    // { label: 'weight', tPrefix: 'entities.selling-order', width: 250 },
  ].forEach((field) =>
    _columns.push({
      field: field.label,
      headerName: t(`${field.tPrefix || 'global'}.${field.label}`),
      width: field.width,
      renderCell: field.render || baseCell
    })
  );

  _columns.push({
    field: 'actions',
    type: 'actions',
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        key={1}
        icon={<MoveToInboxIcon />}
        onClick={() => {
          console.log(params);
        }}
        label="Genera spedizione"
      />
    ]
  });

  const syncOrders = () => {
    sellingOrderService.forceSync().then(() => toast.info('Sincronizzazione lanciata...'));
  };

  return (
    <TenantPage title={t('menu.orders')}>
      <div>
        <EnhancedDataGrid
          columns={_columns}
          allowSelection={true}
          service={sellingOrderService}
          actions={
            <ButtonGroup size="small" color="secondary" variant="outlined">
              <Tooltip title="Sincronizza" placement="top">
                <Button onClick={syncOrders}>
                  <SyncIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Genera spedizioni da selezionati" placement="top">
                <Button onClick={syncOrders}>
                  <MoveToInboxIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          }
        />
      </div>
    </TenantPage>
  );
}

export default SellingOrderList;
