import { useEffect } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';

interface TenantPageProps {
  title: string;
  subtitle?: string;
  menuRight?;
  children;
  breadcrumbs?: { label: string; path: string }[];
}

export const TenantPage = (props: TenantPageProps) => {
  const { title, subtitle, children, menuRight } = props;

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div>
      <div className="flex-shrink-0 flex justify-between items-center h-12 py-2 bg-primary-100 md:px-8 sticky top-0 z-10">
        <div className="flex flex-col items-start">
          <Breadcrumbs />
        </div>
        <div>{menuRight}</div>
      </div>

      <div className="sm:px-2 md:px-8 sm:py-2 md:py-4">
        <div className="mb-4 mt-2">
          <h2 className="h2 font-bold text-lg text-left">{title}</h2>
          {subtitle && <h4 className="text-left text-md leading-2 text-primary-500">{subtitle}</h4>}
        </div>
        {children}
      </div>
    </div>
  );
};
export default TenantPage;
