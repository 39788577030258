import { IWorkstation } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class WorkstationService extends BaseService<IWorkstation> {
  constructor() {
    super('workstations');
  }

  newNonProductiveLink = async (
    workstation_origin: number,
    workstation_destination: number,
    weight: number
  ) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/non-productive-link/`, {
      workstation_origin,
      workstation_destination,
      weight
    });
    return res.data;
  };
}

export const workstationService = new WorkstationService();
