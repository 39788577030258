import { IAugmentedAuditLog } from 'interfaces';
import { ReactNode, useEffect, useState } from 'react';
import { BaseService } from 'services/api/BaseService';
import { classNames } from 'helpers/classNames';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';
interface EntityHistoryProps {
  entityId: number | string;
  service: BaseService<any>;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

function EntityHistory(props: EntityHistoryProps) {
  const { service, entityId } = props;
  const [history, setHistory] = useState<IAugmentedAuditLog[]>([]);

  const changesText = (changes): ReactNode => {
    return (
      <ul>
        {Object.entries(JSON.parse(changes)).map(([key, value]) => (
          <li key={key}>
            {key}: {value[0]} &rarr; <b className="text-primary-500">{value[1]}</b>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    // service.history(entityId).then(setHistory);
  }, []);
  return (
    <>
      <div className="bg-orange-500"></div>
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {history.map((event, eventIdx) => (
            <HtmlTooltip
              key={uuidv4()}
              title={<React.Fragment>{changesText(event.changes)}</React.Fragment>}
              placement="top">
              <li className="cursor-help">
                <div className="relative pb-8">
                  {eventIdx !== history.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                          event.iconBackground
                        )}>
                        <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-500">
                          {event.actor_id__username} {event.additional_data}
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        <time dateTime={event.timestamp}>
                          {dayjs(event.timestamp).format('hh:mm DD/MM/YYYY')}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </HtmlTooltip>
          ))}
        </ul>
      </div>
    </>
  );
}

export default EntityHistory;
