import { Card, CardContent } from '@mui/material';
import { productService, stockQuantityService, warehouseService } from 'services/api';
import { IProduct, IWarehouse } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useParams } from 'react-router-dom';
import FileUploader from 'components/common/file-uploader';
import { GridColDef } from '@mui/x-data-grid';
import EntityHistory from 'components/widgets/EntityHistory';
import SectionTitle from 'components/common/SectionTitle';
import ProductPrices from '../ProductPrices';
import ProductForm from '../ProductForm';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';
import AssignProductToLocation from '../AssignProductToLocation';

const downloadableFields = ['ifu', 'ce_ivd', 'safety_document', 'technical_file'];

export const EditProduct = () => {
  const translationPrefix = 'pages.product.edit';

  const { productId } = useParams();
  const [product, setProduct] = useState<IProduct>();
  const [warehouses, setWarehouses] = useState<IWarehouse[]>();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    // Load current product
    productService.get(+productId).then(setProduct);
    warehouseService.getAllBaseInformation().then(setWarehouses);
    dispatch(loadingActions.stopLoading());
  }, []);

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    productService.update(product.id, values).then(() => {
      enqueueSnackbar('Prodotto aggiornato con successo', { variant: 'success' });
    });
    dispatch(loadingActions.stopLoading());
  };

  const warehouseName = (whId) => {
    if (warehouses) {
      const warehouse = warehouses.find((wh) => wh.id === whId);
      return warehouse.description;
    } else {
      return '';
    }
  };

  const columns: GridColDef[] = StockQuantitiesColumns();

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`, { id: product?.id })}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      {product && (
        <div className="flex flex-wrap">
          <div className="py-4 w-full p-4">
            <ProductForm product={product} onSubmit={onSubmit} />
            <div className="py-4 w-full">
              <SectionTitle>{t(`global.attachments`)}</SectionTitle>
              <Card variant="outlined">
                <CardContent>
                  <div className="flex flex-wrap">
                    {downloadableFields.map((field, i) => (
                      <div key={i} className={`w-1/${downloadableFields.length}`}>
                        <span>{t(`entities.product.${field}`)}:</span>
                        <FileUploader
                          service={productService}
                          entityId={product.id}
                          attachmentName={field}
                          canDownload={product[field]}
                        />
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* Prices */}
          <div className="py-4 w-full p-4">
            <ProductPrices contractLines={product.contract_lines} />
          </div>
          {/* Quantities */}
          <div className="w-full p-4">
            <Card variant="outlined">
              <CardContent>
                <b className="text-primary-600">{t('menu.stock')}</b>
                <br />
                <EnhancedDataGrid
                  service={stockQuantityService}
                  columns={columns}
                  initialParams={{
                    product: product.code
                  }}
                />
              </CardContent>
            </Card>
          </div>
          {/* Locations */}
          {/* <div className="w-full md:w-1/2  p-4">
            <Card variant="outlined">
              <CardContent>
                <SectionTitle>{t('pages.product.location_list')}</SectionTitle>
                <ol>
                  {product.locations.map((location, idx) => (
                    <li key={idx}>
                      {warehouseName(location.warehouse)} - {location.description}
                    </li>
                  ))}
                </ol>
                <AssignProductToLocation productId={product.id} />
              </CardContent>
            </Card>
          </div> */}
          {/* History */}
          <div className="w-full md:w-1/2  p-4">
            {product && (
              <Card variant="outlined">
                <CardContent>
                  <EntityHistory service={productService} entityId={product.id} />
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      )}
    </TenantPage>
  );
};

export default EditProduct;
