import { categoryService } from 'services/api';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import CategoryForm from '../CategoryForm';
import { ICategoryState } from 'interfaces';
import toast from 'features/toast';

const initCategory: ICategoryState = {
  id: null,
  code: '',
  description: '',
  sector: null
};
export const CreateCategory = () => {
  const translationPrefix = 'types.categories.new';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createCategory = async (values) => {
    dispatch(loadingActions.startLoading());
    categoryService.add(values).then((res) => {
      toast.success('Categoria aggiunta con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <CategoryForm category={initCategory} onSubmit={createCategory} />
    </TenantPage>
  );
};

export default CreateCategory;
