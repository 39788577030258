import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { ICategoryState } from 'interfaces';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

interface CategoryFormProps {
  category: ICategoryState;
  onSubmit: any;
}
function CategoryForm(props: CategoryFormProps) {
  const { t } = useTranslation();
  const { category, onSubmit } = props;

  return (
    <div>
      {category && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!category.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-0 md:pr-4">
              <Field
                name="code"
                component={TextFieldAdapter}
                initialValue={category.code}
                label={t('global.code')}
                placeholder={t('global.code')}
                type="text"
              />
            </div>
            <div className="w-full md:w-2/3 ">
              <Field
                name="description"
                component={TextFieldAdapter}
                initialValue={category.description}
                label={t('global.description')}
                placeholder={t('global.description')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default CategoryForm;
