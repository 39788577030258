import { SyntheticEvent, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { IOption } from 'interfaces';

export const AutocompleteAdapter = ({
  input: { name },
  options,
  optionLabel,
  optionValue,
  initValue,
  setValue,
  submitObject,
  freeSolo,
  ...rest
}: any) => {
  const { input, meta } = useField(name);
  const optLabel = optionLabel ?? 'code';
  const optValue = optionValue ?? 'id';

  const onChange = (e: SyntheticEvent, value) => {
    _setValue(value);
    if (value) {
      if (submitObject === true) input.onChange(value);
      else input.onChange(typeof value === 'string' ? value : value[optValue] ?? '');
      if (setValue) setValue(value);
    }
  };

  const disabled = () => {
    if (freeSolo === true) return false;
    return !options.length;
  };

  const getOptionLabel = (option: IOption | string) => {
    switch (typeof option) {
      case 'string':
        return option;

      default:
        return option[optLabel];
    }
  };

  const [_value, _setValue] = useState(null);
  useEffect(() => {
    if (options && options.length > 0) {
      _setValue(options[0]);
      onChange(null, options[0]);
    }
  }, [options]);

  return (
    <Autocomplete
      freeSolo={freeSolo === true}
      disablePortal
      getOptionLabel={(option) => option[optLabel] ?? ''}
      options={options}
      value={_value}
      defaultValue={initValue ?? null}
      disabled={disabled()}
      // onInputChange={onChange} // this breaks things
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option[optValue] === value[optValue]}
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props}>
            {getOptionLabel(option)}
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...rest}
            helperText={meta.touched ? meta.error : ''}
            error={meta.error && meta.touched}
            fullWidth
          />
        );
      }}
    />
  );
};
