import { ISupplier } from 'interfaces';
import { BaseService } from './BaseService';

class SupplierService extends BaseService<ISupplier> {
  constructor() {
    super('suppliers');
  }
  canDelete = true;
}

export const supplierService = new SupplierService();
