import { ICourier } from 'components/selling/models';
import { BaseService } from './BaseService';

class CourierService extends BaseService<ICourier> {
  constructor() {
    super('courier');
  }
}

export const courierService = new CourierService();
