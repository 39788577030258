import { Tab, Tabs } from '@mui/material';
import { TenantPage } from 'components/common';
import { ITenantSettings } from 'interfaces';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { tenantSettingsService } from 'services/api';

function ReportList() {
  const [settings, setSettings] = useState<ITenantSettings>();
  const [value, setValue] = useState<number | string>(1);
  const navigate = useNavigate();

  useEffect(() => {
    tenantSettingsService.get(1).then(setSettings);
  }, []);

  useEffect(() => {
    if (value) {
      navigate(`${value}`);
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(+newValue);
  };
  return (
    <TenantPage title="Dashboard e reportistica" subtitle="">
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example">
        {[
          { id: 1, title: 'Report 1' },
          { id: 2, title: 'Report 2' },
          { id: 3, title: 'Report 3' }
        ].map((dashboard) => (
          <Tab key={dashboard.id} value={dashboard.id} label={dashboard.title}></Tab>
        ))}
      </Tabs>
      <Outlet />
    </TenantPage>
  );
}

export default ReportList;
