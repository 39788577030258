import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { productService } from 'services/api';

export default function IncomingPicks() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    // productService.getAllPaginated(10, 0).then((res) => setProducts(res.results));
    productService.getAllBaseInformation().then(setProducts);
  }, []);
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {products.map((product) => (
        <div key={product.id}>
          <ListItem alignItems="flex-start">
            {/* <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar> */}
            <ListItemText
              primary={product.description}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    fontWeight={500}
                    color="text.primary">
                    Disponibile presso:
                  </Typography>
                  <br></br>
                  {'Location 1, Location 4, Location 5 '}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="middle" component="li" />
        </div>
      ))}
    </List>
  );
}
