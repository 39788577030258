import { ButtonGroup, IconButton } from '@mui/material';
import TenantPage from 'components/common/TenantPage';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { headquarterService, locationService, warehouseService } from 'services/api';
import { BaseService } from 'services/api/BaseService';
import DeleteIcon from '@mui/icons-material/Delete';
import { IHeadquarter, ILocation, IWarehouse } from 'interfaces';
import LaboratoryForm from '../LaboratoryForm';
import WarehouseForm from '../WarehouseForm';
import { useTranslation } from 'react-i18next';
import LocationForm from '../LocationForm';
import toast from 'features/toast';

function EditLayer() {
  const { layer, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [service, setService] = useState<BaseService<any>>();
  const [entity, setEntity] = useState<IHeadquarter | IWarehouse | ILocation | any>();

  useEffect(() => {
    switch (layer) {
      case 'lab':
        setService(headquarterService);
        break;
      case 'warehouse':
        setService(warehouseService);
        break;
      case 'location':
        setService(locationService);
        break;
    }
  }, []);

  useEffect(() => {
    if (service && id !== 'new') {
      service.get(+id).then(setEntity);
    } else {
      setEntity({});
    }
  }, [service]);

  const deleteEntity = () => {
    if (confirm('are you sure?')) {
      service.delete(+id).then(() => navigate('..'));
    }
  };

  const updateEntity = (values) => {
    if (id !== 'new') {
      service.update(entity.id, values).then(() => toast.success(t('messages.entity-updated')));
    } else {
      service.add(values).then(() => toast.success(t('messages.entity-added')));
    }
  };

  return (
    <TenantPage
      title={''}
      subtitle={''}
      menuRight={
        <ButtonGroup>
          <IconButton onClick={() => deleteEntity()}>
            <DeleteIcon />
          </IconButton>
        </ButtonGroup>
      }>
      <div>
        {layer === 'lab' && <LaboratoryForm laboratory={entity} onSubmit={updateEntity} />}
        {layer === 'warehouse' && (
          <WarehouseForm warehouse={entity as IWarehouse} onSubmit={updateEntity} />
        )}
        {layer === 'location' && (
          <LocationForm location={entity as ILocation} onSubmit={updateEntity} />
        )}
      </div>
    </TenantPage>
  );
}

export default EditLayer;
