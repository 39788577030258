import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import { persistor, store } from './app/store';
import reportWebVitals from './reportWebVitals';

import './services/i18n';
import { SnackbarProvider } from 'notistack';
import { injectStore } from 'services/axios/interceptors';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY);
injectStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Routes>
              <Route
                path="/*"
                element={
                  // <SnackbarProvider maxSnack={3}>
                  <App />
                  // </SnackbarProvider>
                }
              />
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
