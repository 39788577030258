import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { loadingActions } from 'features';
import { required } from 'helpers/validators';
import { IContract, IOption } from 'interfaces';
import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { headquarterService, productService, supplierService } from 'services/api';

interface ContractHeaderFormParams {
  contract: IContract;
  onSubmit: any;
  onProductsUpdate: any;
}
function ContractHeaderForm(params: ContractHeaderFormParams) {
  const { contract, onSubmit, onProductsUpdate } = params;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [suppliers, setSuppliers] = useState<IOption[]>([]);
  const [laboratories, setLaboratories] = useState<IOption[]>([]);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    // Load suppliers and laboratories
    productService.getAllBaseInformation().then((res) => onProductsUpdate(res));
    supplierService.getAllBaseInformation().then(setSuppliers);
    headquarterService.getAllBaseInformation().then(setLaboratories);
    dispatch(loadingActions.stopLoading());
  }, []);

  return (
    <div>
      <ReactFinalForm onSubmit={onSubmit} hideControls={true} formId="contractForm">
        <div className="flex flex-wrap mt-4">
          <div className="w-6/12 mb-4 pr-4">
            <Field
              name="code"
              initialValue={contract.code}
              component={TextFieldAdapter}
              label={t('global.code')}
              placeholder={t('global.code')}
              type="text"
              validate={required}
            />
          </div>
          <div className="w-1/6 mb-4 pr-4">
            <Field
              name="drafting_date"
              initialValue={contract.drafting_date}
              component={DatePickerAdapter}
              label={t('global.drafting_date')}
              type="date"
            />
          </div>
          <div className="w-1/6 mb-4 pr-4">
            <Field
              name="expiration_date"
              initialValue={contract.expiration_date}
              component={DatePickerAdapter}
              label={t('global.expiration')}
              placeholder={t('global.expiration')}
              type="date"
              validate={required}
            />
          </div>
          <div className="w-1/6 mb-4">
            <Field
              name="active"
              initialValue={contract.active}
              component={CheckboxAdapter}
              label={t('global.active')}
              placeholder={t('global.active')}
              type="checkbox"
            />
          </div>
          <div className="w-1/2 mb-4 pr-4">
            <Field
              name="tender_ref"
              initialValue={contract.tender_ref}
              component={TextFieldAdapter}
              label={t('global.tender')}
              type="text"
            />
          </div>
          <div className="w-1/2 mb-4">
            <Field
              name="commission_ref"
              initialValue={contract.commission_ref}
              component={TextFieldAdapter}
              label={t('global.commission')}
              type="text"
            />
          </div>
          <div className="w-full mb-4">
            <Field
              name="notes"
              initialValue={contract.notes}
              component={TextFieldAdapter}
              label={t('global.notes')}
              type="text"
            />
          </div>
          <div className="w-1/2 mb-4 pr-4">
            {suppliers && (
              <Field
                name="supplier"
                component={AutocompleteAdapter}
                initValue={contract.supplier}
                defaultValue={contract.supplier.id}
                options={suppliers}
                optionlabel={t('global.code')}
                optionValue="code"
                label={t('global.supplier')}
                type="text"
              />
            )}
          </div>

          <div className="w-1/2 mb-4">
            {laboratories && (
              <Field
                name="laboratories"
                component={MultipleAutocompleteAdapter}
                options={laboratories}
                initValue={contract.laboratories}
                label={t('global.laboratories')}
                type="text"
              />
            )}
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default ContractHeaderForm;
