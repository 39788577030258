import { GridColDef, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export function SuppliersColumns(): GridColDef[] {
  const translationPrefix = 'entities.supplier';
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: 'code',
      headerName: t(`global.code`),
      renderCell: (params: GridRenderCellParams) => {
        const supplier = params.row;
        return (
          <div>
            <Link to={`${supplier.id}`}>
              <b className="text-secondary-500">{supplier.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'active',
      headerName: t(`global.status`),
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={value ? 'text-green-500' : 'text-orange-500'}>
              {t(`global.active-options.${value ? 'ACTIVE' : 'NOT ACTIVE'}`)}
            </span>
          </div>
        );
      }
    }
  ];
  [
    'company_name',
    'city',
    'district',
    'zip_code',
    'address',
    'address_2',
    'vat_number',
    'fiscal_code',
    'email',
    'email_credit_note',
    'phone_number',
    'fax_number',
    'iban',
    'payment_terms'
  ].forEach((element) => {
    columns.push({ field: element, headerName: t(`${translationPrefix}.${element}`), width: 150 });
  });
  return columns;
}
