import { NavLink } from 'react-router-dom';

function NotFound() {
  return (
    <>
      <main className="relative isolate min-h-full h-screen">
        <img
          src="/404.jpg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8 flex flex-col justify-center items-center">
          <img src="/logo-extended.png" className="h-10" />
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">404</h1>
          {/* <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
            404
          </h1> */}
          <p className="mt-4 text-base text-white/70 sm:mt-6">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex justify-center">
            <NavLink to="/" className="text-sm font-semibold leading-7 text-white">
              <span aria-hidden="true">&larr;</span> Back to home
            </NavLink>
          </div>
        </div>
      </main>
    </>
  );
}

export default NotFound;
