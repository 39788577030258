import { IContract } from 'interfaces';
import { BaseService } from './BaseService';

class ContractLineService extends BaseService<IContract> {
  constructor() {
    super('contract-lines');
  }
}

export const contractLineService = new ContractLineService();
