import { IHeadquarter } from 'interfaces';
import { BaseService } from './BaseService';

class HeadquarterService extends BaseService<IHeadquarter> {
  constructor() {
    super('headquarters');
  }

  // Custom methods
}

export const headquarterService = new HeadquarterService();
