import { GridColDef, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DataGridCell from 'components/common/DataGridCell';
import FileUploader from 'components/common/file-uploader';
import { productService } from 'services/api';
import { filterOperators, numberFilterOperators } from './../../../../constants';
import { Link } from 'react-router-dom';
import { currencyFormatter } from 'helpers/currency-formatter';
import { baseCell } from '../cells/base-cell';

export function ProductsColumns(
  getContractLines,
  types: string[],
  onUploadSuccess: any
): GridColDef[] | GridColumns {
  const { t } = useTranslation();
  const translationPrefix = 'entities.product';
  const columns: GridColDef[] | GridColumns = [
    {
      field: 'code',
      headerName: t(`global.code`),
      filterOperators,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const product = params.row;
        return (
          <div>
            <Link to={`${product.id}`}>
              <b className="text-secondary-500">{product.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 350,
      renderCell: baseCell
    },
    {
      field: 'type',
      headerName: t(`global.type`),
      type: 'singleSelect',
      valueOptions: types,
      width: 100,
      renderCell: baseCell
    },
    {
      field: 'category',
      headerName: t(`global.category`),
      filterOperators,
      width: 100,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`${translationPrefix}.status`),
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={value === 'Attivo' ? 'text-green-500' : 'text-orange-500'}>
              {value}
            </span>
          </div>
        );
      }
    },
    {
      field: 'contract_codes',
      valueGetter: getContractLines,
      headerName: t(`${translationPrefix}.contract_codes`),
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const contract_codes = params.value.map(({ contract_code }) => contract_code);
        return <DataGridCell content={contract_codes.join(', ')} />;
      },
      filterable: false
    },
    {
      field: 'unit_prices',
      valueGetter: getContractLines,
      headerName: t(`${translationPrefix}.unit_prices`),
      renderCell: (params: GridRenderCellParams) => {
        const unit_prices = params.value.map(({ unit_price }) => unit_price);
        return <DataGridCell content={unit_prices.map(currencyFormatter).join(' | ')} />;
      },
      width: 200,
      filterable: false
    },
    {
      field: 'suppliers',
      valueGetter: getContractLines,
      headerName: t(`${translationPrefix}.suppliers`),
      renderCell: (params: GridRenderCellParams) => {
        const suppliers = params.value.map(({ supplier }) => supplier);
        return <DataGridCell content={suppliers.join(',')} />;
      },
      width: 300,
      filterable: false
    },
    {
      field: 'expiration_alert_threshold',
      headerName: t(`${translationPrefix}.expiration_alert_threshold`),
      type: 'number',
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell
          content={
            params.value &&
            t(`${translationPrefix}.expiration_alert_threshold_field`, {
              n_days: params.value
            })
          }
        />
      )
    },
    {
      field: 'preservation_temperature',
      headerName: t(`${translationPrefix}.preservation_temperature`),
      type: 'number',
      renderCell: baseCell
    },
    {
      field: 'locations',
      headerName: t(`${translationPrefix}.locations`),
      renderCell: baseCell
    },
    {
      field: 'uom',
      headerName: t(`${translationPrefix}.uom`),
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'packaging',
      headerName: t(`${translationPrefix}.packaging`),
      filterOperators,
      renderCell: baseCell
    }
  ];

  ['restock_threshold', 'understock_threshold', 'overstock_threshold'].forEach((threshold) => {
    columns.push({
      field: threshold,
      headerName: t(`${translationPrefix}.${threshold}`),
      type: 'number',
      width: 100,
      editable: true,
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    });
  });

  ['technical_file', 'safety_document', 'ifu', 'ce_ivd'].forEach((file) => {
    columns.push({
      field: file,
      headerName: t(`${translationPrefix}.${file}`),
      renderCell: (params: GridRenderCellParams) => {
        const product = params.row;
        return (
          <div>
            <FileUploader
              onUploadSuccess={onUploadSuccess}
              service={productService}
              entityId={product.id}
              attachmentName={file}
              canDownload={product[file]}
            />
          </div>
        );
      },
      width: 150,
      filterable: false
    });
  });

  columns.push({
    field: 'ivdr_class',
    headerName: t(`${translationPrefix}.ivdr_class`),
    width: 100,
    renderCell: (params: GridRenderCellParams<string>) => <DataGridCell content={params.value} />
  });

  return columns;
}
