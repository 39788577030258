import {
  Products,
  CreateProduct,
  ProductsSkuMapping,
  EditProduct,
  Categories,
  CreateCategory,
  Types,
  CreateType,
  Suppliers,
  AddSupplier,
  Contracts,
  AddContract,
  StockQuantities,
  StockQuantitiesDetail,
  Movements,
  MovementReasons,
  PurchaseRequest,
  PurchaseRequestList,
  PurchaseOrderList,
  EditContract,
  EditSupplier,
  EditCategory,
  EditType,
  ConsumptionDetail,
  DeliveryExpected,
  CollapsedMovements
} from 'components/inventory';
import {
  Topology,
  TopologyTree,
  Login,
  NotFound,
  UnderDevelop,
  NotificationList,
  UsersManager,
  TenantSettings
} from 'components/common';
import { Navigate, Route, Routes } from 'react-router-dom';
import Public from './layouts/Public';
import Tenant from './layouts/Tenant';
import RequireAuth from './components/RequireAuth';
import TenantHome from './pages/tenant/Home';
import Base from 'pages/Base';
import ReportViewer from 'components/Reporting/ReportViewer';
import EditLayer from 'components/common/Topology/edit-layer/EditLayer';
import UnapprovedMovements from 'components/inventory/Movements/list-unapproved/UnapprovedMovements';
import AddMovementReason from 'components/inventory/Movements/reasons/add/AddMovementReason';
import EditMovementReason from 'components/inventory/Movements/reasons/edit/EditMovementReason';
import ReportList from 'components/Reporting/ReportList';
import AllQuantities from 'components/inventory/Stock/all/AllQuantities';
import {
  TransportDocumentDetail,
  TransportDocumentList
} from 'components/invoices/TransportDocument';
import UninvoicedMovements from 'components/inventory/Movements/list-uninvoiced/UninvoicedMovements';
import QuestionList from 'components/Reporting/QuestionList';
import QuestionViewer from 'components/Reporting/QuestionViewer';
import { AddCostElement, CostList, EditCostElement } from 'components/cost-analysis/Costs';
import {
  CourierList,
  PackagingDashboard,
  SellingOrderList,
  SellingShippingList
} from 'components/selling';

const AppRoutes = () => {
  return (
    <Routes>
      {/* public routes */}
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Public />}>
        <Route path="login" element={<Login />} />
        <Route path="under-develop" element={<UnderDevelop />} />
        {/* protected routes */}
        <Route element={<RequireAuth />}>
          {/* tenant admin routes */}
          <Route element={<Tenant />}>
            <Route path="" element={<TenantHome />} />

            {/* INVENTORY */}
            <Route path="inventory">
              <Route path="contracts">
                <Route path="" element={<Contracts />} />
                <Route path="new" element={<AddContract />} />
                <Route path=":contractId" element={<EditContract />} />
              </Route>
              <Route path="suppliers">
                <Route path="" element={<Suppliers />} />
                <Route path="new" element={<AddSupplier />} />
                <Route path=":supplierId" element={<EditSupplier />} />
              </Route>
              <Route path="products">
                <Route path="" element={<Products />} />
                <Route path="new" element={<CreateProduct />} />
                <Route path=":productId" element={<EditProduct />} />
              </Route>
              <Route path="categories">
                <Route path="" element={<Categories />} />
                <Route path="new" element={<CreateCategory />} />
                <Route path=":categoryId" element={<EditCategory />} />
              </Route>
              <Route path="types">
                <Route path="" element={<Types />} />
                <Route path="new" element={<CreateType />} />
                <Route path=":typeId" element={<EditType />} />
              </Route>
              <Route path="skus">
                <Route path="" element={<ProductsSkuMapping />} />
                {/* <Route path="new" element={<CreateType />} /> */}
              </Route>
              {/* SELLING */}
              <Route path="selling">
                <Route path="orders" element={<SellingOrderList />} />
                <Route path="shipping" element={<SellingShippingList />} />
                <Route path="couriers" element={<CourierList />} />
                <Route path="packaging" element={<PackagingDashboard />} />
              </Route>
              <Route path="buying">
                <Route path="purchase_requests">
                  <Route path="" element={<PurchaseRequestList />} />
                  <Route path="new/" element={<PurchaseRequest />} />
                  <Route path="view/:purchaseRequestId" element={<PurchaseRequest />} />
                </Route>
                <Route path="invoices">
                  <Route path="ddt">
                    <Route path="" element={<TransportDocumentList />} />
                    <Route path=":id" element={<TransportDocumentDetail />} />
                  </Route>
                </Route>
              </Route>
              <Route path="orders">
                <Route path="" element={<PurchaseOrderList />} />
                <Route path="delivery" element={<DeliveryExpected />} />
              </Route>
            </Route>
            <Route path="cost-analysis">
              <Route path="reporting" element={<QuestionList />}>
                <Route path=":questionId" element={<QuestionViewer />} />
              </Route>
              {/* <Route path="costs">
                <Route path="" element={<CostList />} />
                <Route path="add" element={<AddCostElement />} />
                <Route path=":costElementId" element={<EditCostElement />} />
              </Route> */}
            </Route>
            <Route path="reporting">
              <Route path="" element={<ReportList />}>
                <Route path=":dashboardId" element={<ReportViewer />} />
              </Route>
            </Route>

            <Route path="movements">
              <Route path="" element={<Movements />} />
              <Route path="consumption" element={<ConsumptionDetail />} />
              <Route path="stock">
                <Route path="" element={<StockQuantities />} />
                <Route path="all" element={<AllQuantities />} />
                <Route path="detail" element={<StockQuantitiesDetail />} />
              </Route>
              <Route path="detail" element={<CollapsedMovements />} />
              <Route path="unapproved" element={<UnapprovedMovements />} />
              <Route path="uninvoiced" element={<UninvoicedMovements />} />
              <Route path="reasons">
                <Route path="" element={<MovementReasons />} />
                <Route path="new" element={<AddMovementReason />} />
                <Route path=":movementReasonId" element={<EditMovementReason />} />
              </Route>
            </Route>
            {/* <Route path="laboratory/assign" element={<AssignLaboratory />} /> */}

            <Route path="notifications" element={<NotificationList />} />

            {/* SETTINGS */}
            <Route path="settings">
              <Route path="settings" element={<TenantSettings />} />
              <Route path="users" element={<UsersManager />} />
              <Route path="topology">
                <Route path="" element={<Topology />} />
                <Route path=":layer/:id" element={<EditLayer />} />
                <Route path="tree" element={<TopologyTree />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
