import { ISellingShipping } from 'components/selling/models';
import { BaseService } from './BaseService';

class SellingShippingService extends BaseService<ISellingShipping> {
  constructor() {
    super('selling_shipping');
  }
}

export const sellingShippingService = new SellingShippingService();
