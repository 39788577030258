import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export function imgCell<T>(path: string, filetype?: string) {
  // eslint-disable-next-line react/display-name
  return (params: GridRenderCellParams) => {
    return (
      <div>
        <img className="w-auto h-8" src={`${path}/${params.value}${filetype}`} />
      </div>
    );
  };
}
