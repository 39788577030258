import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { Button, ButtonGroup, Link } from '@mui/material';
import { productService, skuMappingService } from 'services/api';
import { Field } from 'react-final-form';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IOption } from 'interfaces';

import { loadingActions } from 'features';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useAppDispatch } from 'app/store';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CollapsableColumns from 'components/common/CollapsableColumns';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import ReactFinalForm from 'components/form/ReactFinalForm';
import TenantPage from 'components/common/TenantPage';
import { filterOperators } from '../../../constants';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import DataGridCell from 'components/common/DataGridCell';

export const ProductsSkuMapping = () => {
  const { t } = useTranslation();
  const translationPrefix = 'pages.sku';
  const dispatch = useAppDispatch();

  const columns: GridColDef[] = [
    // { field: 'sku', headerName: t(`${translationPrefix}.entity.sku`), width: 150 },
    // { field: 'product', headerName: t(`global.product`), width: 250 },
    // { field: 'description', headerName: t(`global.description`), flex: 1 },
    {
      field: 'sku',
      headerName: t(`${translationPrefix}.entity.sku`),
      filterOperators,
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.value} sx={{ fontWeight: 'bold' }} />
      )
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 300,
      valueGetter: (params) => params.row.product.code,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      flex: 1,
      valueGetter: (params) => params.row.product.description ?? '',
      renderCell: baseCell
    }
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [products, setProducts] = useState<IOption[]>([]);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    productService.getAllBaseInformation().then((res) => {
      setProducts(res);
      dispatch(loadingActions.stopLoading());
    });
  }, []);

  const onSubmitCreate = async (values) => {
    dispatch(loadingActions.startLoading());
    skuMappingService.add(values).then(location.reload);
    dispatch(loadingActions.stopLoading());
  };

  const onOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <Button onClick={onOpenDialog}>
                <AddIcon />
                {t(`${translationPrefix}.add`)}
              </Button>
            </ButtonGroup>
          </div>
        }>
        <CollapsableColumns
          expand={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          size="w-1/3"
          contentLeft={<EnhancedDataGrid service={skuMappingService} columns={columns} />}
          contentRight={
            <div className="w-full flex flex-wrap">
              <ReactFinalForm onSubmit={onSubmitCreate}>
                <div className="flex flex-wrap my-4">
                  <div className="w-full md:w-full py-2">
                    <Field
                      name="sku"
                      component={TextFieldAdapter}
                      label={t(`${translationPrefix}.entity.sku`)}
                      type="text"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-full py-2">
                    <Field
                      name="product"
                      optionLabel="description"
                      component={AutocompleteAdapter}
                      options={products}
                      label={t(`global.product`)}
                      type="text"
                      validate={required}
                      required
                    />
                  </div>
                </div>
              </ReactFinalForm>
            </div>
          }
        />
      </TenantPage>
    </>
  );
};

export default ProductsSkuMapping;
