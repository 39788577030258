import { IPurchaseOrder } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class OrderService extends BaseService<IPurchaseOrder> {
  constructor() {
    super('purchase-orders');
  }

  async openOrders(limit = 0, offset = 0, params: Record<string, unknown> = {}) {
    const res = await axiosPrivate.get(`${this.modulePrefix}/open-orders/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  }

  downloadAttachment = async (orderId: number) => {
    axiosPrivate
      .get(`${this.modulePrefix}/${orderId}/download-attachment/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}

export const orderService = new OrderService();
