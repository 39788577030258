import { useAppDispatch } from 'app/store';
import { TenantPage } from 'components/common';
import { loadingActions } from 'features';
import { ITenantSettings } from 'interfaces';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tenantSettingsService } from 'services/api';
import TenantSettingsForm from '../TenantSettingsForm';

export const TenantSettings = () => {
  const { t } = useTranslation();
  const translationPrefix = 'pages.tenant-settings.edit';

  const [tenantSettings, setTenantSettings] = useState<ITenantSettings>();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const updateSettings = async (values) => {
    dispatch(loadingActions.startLoading());
    tenantSettingsService.update(1, values).then(() => {
      enqueueSnackbar('Impostazioni salvate con successo', { variant: 'success' });
    });
    dispatch(loadingActions.stopLoading());
  };

  useEffect(() => {
    tenantSettingsService.get(1).then(setTenantSettings);
  }, []);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <TenantSettingsForm tenantSettings={tenantSettings} onSubmit={updateSettings} />
    </TenantPage>
  );
};

export default TenantSettings;
