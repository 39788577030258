import { axiosPrivate } from 'services/axios';

export const fetchEntitySummary = async (
  entityName: string,
  params: Record<string, unknown> = {}
) => {
  const res = await axiosPrivate.get(`${entityName}-summary/`, { params });
  return res.data;
};

export const fetchEntityOptions = async (entityName: string) => {
  const res = await axiosPrivate.options(`${entityName}/`);
  return res.data;
};
