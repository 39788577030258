import { axiosPrivate } from 'services/axios';

class CostAnalysisService {
  private modulePrefix = 'cost_analysis';

  runCostAnalysis = async (date_start: string, date_end: string) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/report`, {
      date_start,
      date_end
    });
    return res.data;
  };
}

export const costAnalysisService = new CostAnalysisService();
