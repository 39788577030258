import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';
import DataGridCell from 'components/common/DataGridCell';
import { useTranslation } from 'react-i18next';
import { printLabel } from 'services/zebra';
import { dateFilterOperators, filterOperators, numberFilterOperators } from '../../../../constants';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { baseCell } from '../cells/base-cell';

const getContractLines = (params) => {
  return params.row.contract_lines;
};

export const StockQuantitiesColumns = (): GridColumns => {
  const translationPrefix = 'entities.stock_quantity';
  const { t } = useTranslation();
  return [
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'category',
      headerName: t(`global.category`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'suppliers',
      valueGetter: getContractLines,
      headerName: t(`${translationPrefix}.suppliers`),
      renderCell: (params: GridRenderCellParams) => {
        const suppliers = params.value.map(({ supplier }) => supplier);
        return <DataGridCell content={suppliers.join(',')} />;
      },
      filterOperators,
      width: 300
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      type: 'date',
      filterOperators: dateFilterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'discounted',
      headerName: t(`${translationPrefix}.discounted`),
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.value ? 'Yes' : 'No'} />
      )
    },
    {
      field: 'quantity',
      headerName: t(`${translationPrefix}.quantity`),
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={1}
          icon={<LocalPrintshopIcon />}
          onClick={() =>
            printLabel(
              params.row['product'],
              params.row['product_batch'],
              params.row['expiration_date']
            )
          }
          label="Print"
        />
      ]
    }
  ];
};
