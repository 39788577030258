import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { OrderDetailColumns } from 'components/common/enhanced/common-headers/order-detail';
import { IPurchaseOrder } from 'interfaces';

interface OrderDetailProps {
  order: IPurchaseOrder;
}

export const OrderDetail = (props: OrderDetailProps) => {
  const { order } = props;
  const columns: GridColDef[] = OrderDetailColumns();

  return (
    <div>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={order.lines} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
      </div>
    </div>
  );
};
