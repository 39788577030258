import { ButtonGroup, Button, TextField, MenuItem } from '@mui/material';
import { IUser } from 'interfaces';
import { TenantRoles } from './../../../../constants';
import { userService } from 'services/api/UserService';
import CollapsableColumns from 'components/common/CollapsableColumns';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { useRef, useState } from 'react';
import toast from 'features/toast';
import { useTranslation } from 'react-i18next';
import { UserColumns } from 'components/common/enhanced/common-headers/users';

const initialUser = {
  id: null,
  email: null,
  username: null,
  first_name: null,
  last_name: null,
  groups: []
};

export const UsersManager = () => {
  const { t } = useTranslation();

  const [userToAdd, setUserToAdd] = useState<IUser>(initialUser);
  const [insertUser, setInsertUser] = useState<boolean>(false);
  const datagridRefresh = useRef(null);

  const columns = UserColumns();
  const translationPrefix = 'pages.users.list';

  const saveUser = () => {
    userService.registerUser(userToAdd).then((u) => {
      setUserToAdd(initialUser);
      setInsertUser(false);
      toast.success('Utente aggiunto con successo');
      datagridRefresh.current();
    });
  };

  const abortInsert = () => {
    setUserToAdd(initialUser);
    setInsertUser(false);
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <ButtonGroup>
          <Button onClick={() => setInsertUser(!insertUser)}>+</Button>
        </ButtonGroup>
      }>
      <CollapsableColumns
        size="w-1/3"
        expand={insertUser}
        onClose={() => setInsertUser(false)}
        contentLeft={
          <EnhancedDataGrid refresh={datagridRefresh} columns={columns} service={userService} />
        }
        contentRight={
          <form
            className="flex flex-wrap"
            onSubmit={(e) => {
              e.preventDefault();
              saveUser();
            }}>
            <div className="w-full md:w-1/2 my-2 md:pr-1 px-2">
              <TextField
                required
                sx={{ width: '100%' }}
                label="Username"
                value={userToAdd.username}
                onChange={(e) => setUserToAdd({ ...userToAdd, username: e.target.value })}
              />
            </div>
            <div className="w-full md:w-1/2 my-2 md:pl-1 px-2">
              <TextField
                sx={{ width: '100%' }}
                select
                label="Role"
                value={userToAdd.groups[0]}
                onChange={(e) => setUserToAdd({ ...userToAdd, groups: [e.target.value] })}>
                {TenantRoles.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="w-full my-2 px-2">
              <TextField
                required
                sx={{ width: '100%' }}
                label="Email"
                type="email"
                value={userToAdd.email}
                onChange={(e) => setUserToAdd({ ...userToAdd, email: e.target.value })}
              />
            </div>
            <div className="w-full md:w-1/2 my-2  md:pr-1 px-2">
              <TextField
                required
                sx={{ width: '100%' }}
                label="Nome"
                value={userToAdd.first_name}
                onChange={(e) => setUserToAdd({ ...userToAdd, first_name: e.target.value })}
              />
            </div>
            <div className="w-full md:w-1/2 my-2  md:pl-1 px-2">
              <TextField
                required
                sx={{ width: '100%' }}
                label="Cognome"
                value={userToAdd.last_name}
                onChange={(e) => setUserToAdd({ ...userToAdd, last_name: e.target.value })}
              />
            </div>
            <div className="w-full my-2 px-2">
              <TextField
                required
                sx={{ width: '100%' }}
                label="Password"
                type="password"
                value={userToAdd.password}
                onChange={(e) => setUserToAdd({ ...userToAdd, password: e.target.value })}
              />
            </div>
            <div className="w-full my-2 px-2">
              <TextField
                required
                sx={{ width: '100%' }}
                label="Password confirmation"
                type="password"
                value={userToAdd.password2}
                onChange={(e) => setUserToAdd({ ...userToAdd, password2: e.target.value })}
              />
            </div>
            <div className="w-full my-2">
              <ButtonGroup className="w-full px-2">
                <Button
                  onClick={abortInsert}
                  color="warning"
                  variant="outlined"
                  sx={{ width: '100%' }}>
                  Cancel
                </Button>
                <Button type="submit" variant="outlined" sx={{ width: '100%' }}>
                  Submit
                </Button>
              </ButtonGroup>
            </div>
          </form>
        }
      />
    </TenantPage>
  );
};

export default UsersManager;
