import { IContract, IContractLine } from 'interfaces';
import { contractRealValue, contractValue } from './utils';

interface ContractInsightsProps {
  contract: IContract;
  lines: IContractLine[];
}

function ContractInsights(props: ContractInsightsProps) {
  const { contract, lines } = props;
  return (
    <div>
      <p className="mx-2 py-0 my-0">
        Valore Contratto: <b>{contractValue(lines)}</b> - Reale valore contratto:
        <b>{contractRealValue(lines)}</b>
      </p>
    </div>
  );
}

export default ContractInsights;
