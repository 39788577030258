import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  gridVisibleSortedRowIdsSelector,
  useGridApiContext
} from '@mui/x-data-grid-pro';
import Button, { ButtonProps } from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import { ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'features/toast';

import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';

const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridVisibleSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt'
);

export const EnhancedToolbarProvider = (service: any, actions?: any) => {
  const CustomToolbar = () => {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

    const exportAll = () => {
      service.exportAll();
      toast.info(t('messages.export-required'));
    };

    const exportButtonProps: ButtonProps = {
      color: 'secondary',
      size: 'small',
      startIcon: <ExportIcon />
    };

    return (
      <GridToolbarContainer className="mb-2" style={{ padding: 0 }}>
        <div className="w-auto flex justify-end">
          <ButtonGroup size="small">
            <GridToolbarColumnsButton color="secondary" />
            <GridToolbarFilterButton color="secondary" />
            <Button
              {...exportButtonProps}
              onClick={() => handleExport({ getRowsToExport: getFilteredRows, delimiter: ';' })}>
              {t('data-grid.download-filtered-rows')}
            </Button>

            {!!service.exportAll && (
              <Button {...exportButtonProps} onClick={exportAll} color="secondary">
                {t('data-grid.export-all')}
              </Button>
            )}

            {!!service.importFromFile && <ImportFileUploader service={service} />}
          </ButtonGroup>
        </div>
        {actions && <div className="w-auto flex justify-start ml-4">{actions}</div>}
      </GridToolbarContainer>
    );
  };
  return CustomToolbar;
};
