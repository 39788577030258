import { Button, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import TenantPage from 'components/common/TenantPage';
import { typeService } from 'services/api';
import SearchBar from 'components/form/SearchBar';
import { GridColDef } from '@mui/x-data-grid';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { TypesColumns } from 'components/common/enhanced/common-headers';

export const Types = () => {
  const translationPrefix = 'pages.type';

  const [queryText, setQueryText] = useState();

  const { t } = useTranslation();

  const columns: GridColDef[] = TypesColumns();

  const onSubmitSearch = async (values) => {
    setQueryText(values['q']);
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <div className="mr-2">
              <SearchBar onSubmit={onSubmitSearch} />
            </div>
            <ButtonGroup>
              <Link to="new">
                <Button color="primary" size="small">
                  <AddIcon />
                  Nuova Tipologia
                </Button>
              </Link>
            </ButtonGroup>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={typeService} fullTextQuery={queryText} />
        </div>
      </TenantPage>
    </>
  );
};

export default Types;
