import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pathnames = pathname.split('/').filter(Boolean);
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      {pathnames.length ? (
        <Link
          underline="hover"
          onClick={() => navigate('/')}
          className="cursor-pointer text-primary-300">
          <HomeIcon
            sx={{ mt: -0.5 }}
            fontSize="small"
            className="cursor-pointer text-primary-300"
          />
        </Link>
      ) : (
        <Typography color="text.primary"> </Typography>
      )}
      {pathnames
        .filter((name) => !['tenant', 'home'].includes(name))
        .map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography key={name} className="cursor-pointer text-primary-300" fontWeight={700}>
              {capitalizedName}
            </Typography>
          ) : (
            <Typography
              className="cursor-pointer text-primary-300 hover:underline"
              key={name}
              onClick={() => navigate(routeTo)}>
              {capitalizedName}
            </Typography>
          );
        })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
