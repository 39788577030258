import axios from 'axios';
import { onResponse, onResponseError } from './interceptors';

const baseURL = process.env.REACT_APP_BASE_URL;

export const axiosPublic = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const axiosPrivate = axios.create({
  baseURL: `${baseURL}api/`,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

axiosPrivate.interceptors.response.use(onResponse, onResponseError);
