import { ISector } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class CategoryService extends BaseService<ISector> {
  constructor() {
    super('categories');
  }

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const categoryService = new CategoryService();
