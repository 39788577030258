import {
  DataGridPro,
  GridColDef,
  itIT,
  GridLinkOperator,
  GridActionsCellItem,
  GridRowParams,
  GridColumns
} from '@mui/x-data-grid-pro';
import { MutableRefObject, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BaseService } from 'services/api/BaseService';
import { EnhancedToolbarProvider } from './EnhancedToolbarProvider';
import { onEditCellCallbackProvider } from './utils';
import { useAppSelector } from 'app/store';
import DeleteIcon from '@mui/icons-material/Delete';
import { ROLE_SUPER_ADMIN } from './../../../../constants';
import toast from 'features/toast';
interface EnhancedDataGridProps {
  service: BaseService<any>;
  columns: GridColDef[] | GridColumns;
  getMethod?: (pageSize: number, offset: number, params: Record<string, string>) => Promise<any>;
  initialParams?: Record<string, string>;
  refresh?: MutableRefObject<any>;
  outerParams?: any;
  fullTextQuery?: string;
  clientSide?: boolean;
  initialFilterItems?: any[];
  initialOptions?: any;
  actions?: any;
  dense?: boolean;
  allowSelection?: boolean;
}

function EnhancedDataGrid(props: EnhancedDataGridProps) {
  const {
    service,
    columns,
    getMethod,
    initialParams,
    refresh,
    outerParams,
    fullTextQuery,
    clientSide,
    initialFilterItems,
    initialOptions,
    dense,
    actions,
    allowSelection
  } = props;

  const groups = useAppSelector((state) => state.auth.groups);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [items, setItems] = useState<any[]>([]);
  const [orderMacro, setOrderMacro] = useState('');
  const [filter, setFilter] = useState<Record<string, string>>(null);

  const defaultWarehouse = useAppSelector((state) => state.auth.warehouse);

  const refreshColumnsData = () => {
    if (!isLoading) {
      setIsLoading(true);
      let searchParams = initialParams ?? {};
      if (initialFilterItems) {
        let filters = {};
        [initialFilterItems].forEach((element) => {
          filters = { ...filters, ..._getFilterObj(element) };
        });
        searchParams = { ...searchParams, ...filters };
      }
      if (fullTextQuery) {
        searchParams['q'] = fullTextQuery;
      }
      if (orderMacro.trim().length !== 0) {
        searchParams['ordering'] = orderMacro;
      }
      if (filter) {
        searchParams = { ...searchParams, ...filter };
      }
      if (outerParams) {
        searchParams = { ...searchParams, ...outerParams };
      }
      if (getMethod) {
        getMethod(pageSize, page * pageSize, searchParams)
          .then((res) => {
            const results = res.results;
            setItems(
              results.map((item) => {
                if (!item?.id) return { ...item, id: uuidv4() };
                return item;
              })
            );
            setCount(res.count);
            setIsLoading(false);
          })
          .catch(() => {
            searchParams = {};
            setIsLoading(false);
            setFilter(null);
            setPage(0);
          });
      } else {
        service
          .getAllPaginated(pageSize, page * pageSize, searchParams)
          .then((res) => {
            setItems(res.results);
            setCount(res.count);
            setIsLoading(false);
          })
          .catch(() => {
            searchParams = {};
            setIsLoading(false);
            setFilter(null);
            setPage(0);
          });
      }
    }
  };

  useEffect(() => {
    if (refresh) refresh.current = refreshColumnsData;
  }, []);

  useEffect(() => {
    refreshColumnsData();
  }, [pageSize, page, fullTextQuery, orderMacro, filter, outerParams, defaultWarehouse]);

  useEffect(() => {
    setPage(0);
  }, [fullTextQuery, filter, orderMacro]);

  const onSortingChange = (x) => {
    if (x.length > 0) setOrderMacro(`${x[0].sort === 'desc' ? '-' : ''}${x[0].field}`);
    else setOrderMacro('');
  };

  const onFilterChange = (x) => {
    let filters = {};
    x.items.forEach((element) => {
      filters = { ...filters, ..._getFilterObj(element) };
    });
    setFilter(filters);
  };

  function _getFilterObj(item): Record<string, string> {
    if (
      ['contains', '=', 'is', 'equals'].includes(item.operatorValue) &&
      item.value?.trim()?.length > 0
    ) {
      return { [item.columnField]: item.value };
    } else if (['>', 'after'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [`${item.columnField}__gt`]: item.value };
    } else if (['>=', 'onOrAfter'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [`${item.columnField}__gte`]: item.value };
    } else if (['<', 'before'].includes(item.operatorValue) && item.value?.trim()?.length > 0) {
      return { [`${item.columnField}__lt`]: item.value };
    } else if (
      ['<=', 'onOrBefore'].includes(item.operatorValue) &&
      item.value?.trim()?.length > 0
    ) {
      return { [`${item.columnField}__lte`]: item.value };
    } else {
      return null;
    }
  }

  const onEditCell = onEditCellCallbackProvider(service);

  const _columns = [...columns];

  if (!!service && service.canDelete && groups && groups.includes(ROLE_SUPER_ADMIN)) {
    _columns.push({
      field: '',
      type: 'actions',
      // headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            key={0}
            label="Delete"
            icon={<DeleteIcon color="error" />}
            onClick={() =>
              service.delete(params.row['id']).then(() => {
                refreshColumnsData();
                toast.success('deleted successfully');
              })
            }
          />
        ];
      },
      filterable: false
    });
  }

  return (
    <div style={{ width: '100%' }}>
      {/* Hide multi link operator input */}
      <style>{'.MuiDataGrid-filterFormLinkOperatorInput{display:none}'}</style>
      <DataGridPro
        checkboxSelection={allowSelection}
        initialState={{
          filter: {
            filterModel: {
              items: [...(initialFilterItems || [])],
              linkOperator: GridLinkOperator.And,
              quickFilterLogicOperator: GridLinkOperator.And
            }
          },
          pinnedColumns: {
            left: ['code', 'approved', 'moved_quantity', 'quantity'],
            right: ['actions']
          },
          ...(initialOptions || {})
        }}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        sx={{ border: 'none' }}
        autoHeight
        disableSelectionOnClick
        pagination
        density={dense ? 'compact' : 'standard'}
        columns={_columns}
        pageSize={pageSize}
        loading={isLoading}
        page={page}
        rows={items}
        onPageChange={(page) => {
          setPage(page);
        }}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={count}
        paginationMode={clientSide ? 'client' : 'server'}
        sortingMode={clientSide ? 'client' : 'server'}
        filterMode={clientSide ? 'client' : 'server'}
        onSortModelChange={onSortingChange}
        onFilterModelChange={onFilterChange}
        components={{
          Toolbar: EnhancedToolbarProvider(service, actions)
        }}
        onCellEditCommit={onEditCell}
      />
    </div>
  );
}

export default EnhancedDataGrid;
