import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DataGridCell from 'components/common/DataGridCell';
import { currencyFormatter } from 'helpers/currency-formatter';
import { baseCell } from '../cells/base-cell';

export function ContractLinesColumns(
  translationPrefix: string,
  onRemoveLine,
  onEditLine
): GridColumns {
  const { t } = useTranslation();
  return [
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 150,
      valueGetter: (params) => params.row.product.code
    },
    {
      field: 'product_description',
      headerName: t(`global.product_description`),
      width: 250,
      valueGetter: (params) => params.row.product.description
    },
    {
      field: 'unit_price',
      headerName: t(`entities.contract_line.unit_price`),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const price = params.value;
        return <DataGridCell content={currencyFormatter(price)} />;
      }
    },
    {
      field: 'initial_available_units',
      headerName: t(`entities.contract_line.initial_available_units`),
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'available_units',
      headerName: t(`entities.contract_line.available_units`),
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'initial_discounted_units',
      headerName: t(`entities.contract_line.initial_discounted_units`),
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'available_discounted_units',
      headerName: t(`entities.contract_line.available_discounted_units`),
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={1}
          icon={<EditIcon />}
          onClick={() => onEditLine(params.row)}
          label="Print"
        />,
        <GridActionsCellItem
          key={2}
          icon={<DeleteIcon />}
          onClick={() => onRemoveLine(params.row)}
          label="Print"
        />
      ]
    }
  ];
}
