import { classNames } from 'helpers/classNames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useState } from 'react';

const menuItemClasses = ({ isActive }) =>
  classNames(
    isActive
      ? 'bg-primary-600 text-white'
      : 'text-neutral-100 hover:bg-primary-600 hover:text-neutral-100',
    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
  );

const MenuVoice = (group, item, collapsed) => {
  const { t } = useTranslation();

  return (
    <div key={item.name} className="mt-2 last:mb-2">
      <NavLink to={`${group.base_url}/${item.href}`} end={item.end} className={menuItemClasses}>
        <div className="flex">
          <Tooltip title={t(`menu.${item.name}`)} placement="right">
            <item.icon
              fontSize="small"
              className={({ collapsed }) =>
                (!collapsed ? 'mr-4' : '') + 'flex-shrink-0 h-6 w-6 text-neutral-100'
              }
            />
          </Tooltip>
          {!collapsed && <span className="mx-2">{t(`menu.${item.name}`)}</span>}
        </div>
      </NavLink>
    </div>
  );
};

export const Menu = (navigation: any[], collapsed?: boolean) => {
  const [expanded, setExpanded] = useState<string>();
  const { t } = useTranslation();

  // TODO: implement auth checks
  const haveRole = (group) => true;

  return (
    <nav className="px-2 pt-2 space-y-1">
      {/* Home voice */}
      {MenuVoice({ base_url: '' }, { name: 'home', href: '', icon: DashboardIcon }, collapsed)}
      {/* Navigation groups */}
      {navigation.filter(haveRole).map((group, index) => (
        <div key={`g_${index}`}>
          <NavLink
            to={group.base_url}
            className={menuItemClasses}
            onClick={(event) => {
              event.preventDefault();
              setExpanded(group.name);
            }}>
            <group.icon
              className={({ collapsed }) =>
                (!collapsed ? 'mr-4' : '') + 'mr-4 flex-shrink-0 h-6 w-6 text-secondary-100'
              }
            />
            {!collapsed && <span className="mx-2">{t(`menu.group.${group.name}`)}</span>}
          </NavLink>
          {
            <div
              className={`overflow-hidden ease-linear transition-all duration-150 ${
                expanded !== group.name ? 'h-0' : 'h-50'
              } ${collapsed ? 'ml-0' : 'ml-4'}`}>
              {group.items && group.items.map((item) => MenuVoice(group, item, collapsed))}
            </div>
          }
        </div>
      ))}
    </nav>
  );
};

export default Menu;
