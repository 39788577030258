import { productService } from 'services/api';
import { IProduct } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import ProductForm from '../ProductForm';
import { useNavigate } from 'react-router-dom';

const initProduct: IProduct = {
  id: null,
  code: null,
  description: null,
  type: null,
  category: null,
  status: null,
  weight: 0,
  dimensions: 0,
  restock_threshold: null,
  understock_threshold: null,
  overstock_threshold: null,
  uom: null,
  packaging: null,
  units_per_package: null,
  ivdr_class: null
};

export const CreateProduct = () => {
  const translationPrefix = 'pages.product.new';

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    productService.add(values).then((res) => {
      if (res.id) {
        enqueueSnackbar('Prodotto aggiunto con successo', { variant: 'success' });
        navigate(`../${res.id}`);
      }
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <ProductForm product={initProduct} onSubmit={onSubmit} />
    </TenantPage>
  );
};

export default CreateProduct;
