import { currencyFormatter } from 'helpers/currency-formatter';
import { useTranslation } from 'react-i18next';

interface ProductPricesProps {
  contractLines: {
    contract_code: string;
    unit_price: string;
    supplier: string;
    commission_ref: string;
    laboratories: string[];
  }[];
}

function ProductPrices(props: ProductPricesProps) {
  const { t } = useTranslation();

  return (
    <div>
      <b className="text-primary-600">{t('global.prices')}</b>
      <ul className="mt-4">
        {props.contractLines &&
          props.contractLines.map((cl, i) => (
            <li key={i} className="flex justify-between mb-2">
              <span>
                {cl.contract_code} {cl.commission_ref && <b>{`(ref: ${cl.commission_ref})`}</b>}
                {cl.laboratories && (
                  <span className="ml-4">{` lab: ${cl.laboratories.join(',')}`}</span>
                )}
              </span>
              <b className="text-primary-600">{currencyFormatter(cl.unit_price)}</b>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ProductPrices;
