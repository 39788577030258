import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import NotificationWidget from 'components/widgets/NotificationWidget';
import ExpiringContracts from 'components/widgets/ExpiringContracts';
import ExpiringProducts from 'components/widgets/ExpiringProducts';
import DeliveryExpectedWidget from 'components/widgets/DeliveryExpectedWidget';
import PurchaseRequestsToApprove from 'components/widgets/PurchaseRequestsToApprove';
import StockWidget from 'components/widgets/StockWidget';
import MovementsWidget from 'components/widgets/MovementsWidget';
import SellingShippingStatusWidget from 'components/widgets/SellingShippingStatusWidget';
import CountersWidget from 'components/widgets/CountersWidget';

export default function TenantHome() {
  const { t } = useTranslation();
  const translationPrefix = 'dashboard';

  return (
    <TenantPage title={t(`${translationPrefix}.title`)}>
      <div className="flex w-full flex-wrap">
        <div className="w-full flex flex-wrap">
          <div className="w-3/5 flex flex-wrap p-2">
            <div className="w-full pb-4 border-b border-primary-200">
              <CountersWidget />
              <br></br>
              <StockWidget />
            </div>
            <div className="w-1/2 p-4">
              <DeliveryExpectedWidget />
            </div>

            <div className="w-1/2 p-4">
              <PurchaseRequestsToApprove />
            </div>
          </div>

          <div className="w-2/5 flex flex-col flex-wrap p-2 px-4 border-l border-primary-200">
            <div className="w-full pb-4 border-b border-primary-200">
              <MovementsWidget />
            </div>

            <div className="w-full py-4">
              <NotificationWidget />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-wrap">
          <div className="w-1/2 p-4">
            {/* <SellingShippingStatusWidget /> */}
            <ExpiringContracts />
          </div>

          <div className="w-1/2 p-4">
            <ExpiringProducts />
          </div>
        </div>
      </div>
    </TenantPage>
  );
}
