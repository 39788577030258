import { Button, ButtonGroup } from '@mui/material';
import { contractService } from 'services/api';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchBar from 'components/form/SearchBar';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import FileUploader from 'components/common/file-uploader';
import dayjs from 'dayjs';

export const Contracts = () => {
  const translationPrefix = 'pages.contract.list';
  const { t } = useTranslation();

  const datagridRefresh = useRef(null);
  const [queryText, setQueryText] = useState<string>();

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t(`global.code`),
      renderCell: (params: GridRenderCellParams) => {
        const contract = params.row;
        return (
          <div>
            <Link to={`${contract.id}`}>
              <b className="text-secondary-500">{contract.code}</b>
            </Link>
          </div>
        );
      }
    },
    { field: 'supplier', headerName: t(`global.supplier`), flex: 1 },
    {
      field: 'laboratories',
      headerName: t(`global.laboratories`),
      valueGetter: (params) => params.row.laboratory_codes,
      flex: 1
    },
    {
      field: 'active',
      headerName: t(`global.status`),
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={value ? 'text-green-500' : 'text-orange-500'}>
              {t(`global.active-options.${value ? 'ACTIVE' : 'NOT ACTIVE'}`)}
            </span>
          </div>
        );
      }
    },
    { field: 'tender_ref', headerName: t(`entities.contract.tender_ref`), flex: 1 },
    { field: 'commission_ref', headerName: t(`entities.contract.commission_ref`), flex: 1 },
    {
      field: 'drafting_date',
      headerName: t(`entities.contract.drafting_date`),
      flex: 1
    },
    {
      field: 'expiration_date',
      headerName: t(`entities.contract.expiration_date`),
      type: 'date',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        const diff = -1 * dayjs().diff(value, 'months');

        return (
          <div>
            {diff < 3 && <NewReleasesIcon color="warning" />}
            <span className="ml-2">{value}</span>
          </div>
        );
      }
    },
    {
      field: 'commercial_bid',
      headerName: t(`entities.contract.commercial_bid`),
      renderCell: (params: GridRenderCellParams) => {
        const contract = params.row;
        return (
          <div>
            <FileUploader
              service={contractService}
              entityId={contract.id}
              attachmentName={'commercial_bid'}
              canDownload={contract.commercial_bid}
            />
          </div>
        );
      },
      width: 150,
      filterable: false
    },
    {
      field: 'bid_approval',
      headerName: t(`entities.contract.bid_approval`),
      renderCell: (params: GridRenderCellParams) => {
        const contract = params.row;
        return (
          <div>
            <FileUploader
              service={contractService}
              entityId={contract.id}
              attachmentName={'bid_approval'}
              canDownload={contract.bid_approval}
            />
          </div>
        );
      },
      width: 150,
      filterable: false
    }
  ];

  const onSubmitSearch = async (values) => {
    setQueryText(values['q']);
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <div className="mr-2">
              <SearchBar onSubmit={onSubmitSearch} />
            </div>
            <div>
              <ButtonGroup>
                <Link to="new">
                  <Button variant="outlined" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add-new`)}
                  </Button>
                </Link>
              </ButtonGroup>
            </div>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid columns={columns} service={contractService} refresh={datagridRefresh} />
        </div>
      </TenantPage>
    </>
  );
};

export default Contracts;
