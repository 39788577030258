import toast from 'features/toast';
import { IRegionalRate } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class RegionalRateService extends BaseService<IRegionalRate> {
  constructor() {
    super('regional-rates');
  }
  canDelete = true;

  async getAllBaseInformation(params: Record<string, unknown> = {}) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}/list-all/`, { params });
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }
}

export const regionalRateService = new RegionalRateService();
