import { FormEvent, useEffect, useState } from 'react';
import { loadingActions } from 'features/loadingSlice';
import { authActions, login } from 'features';
import { useAppDispatch } from 'app/store';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import toast from 'features/toast';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Input, TextField, Typography } from '@mui/material';

export const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const greet = (username: string) => {
    enqueueSnackbar(`Hi ${username} :)`);
  };

  useEffect(() => {
    const user = searchParams.get('user');
    const role = searchParams.get('role');
    if (user && role) {
      dispatch(authActions.superadminLogin({ user, groups: [role] }));
      navigate('/');
      greet(user);
    }
  }, []);

  const performLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(loadingActions.startLoading());
    await dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        setTimeout(() => {
          navigate('/');
        }, 1000);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setTimeout(() => {
          dispatch(loadingActions.stopLoading());
        }, 1000);
        setUsername('');
        setPassword('');
      });
  };

  return (
    <>
      <div className="min-h-screen bg-white flex justify-center p-0 m-0">
        <div className="md:w-1/2 flex flex-col justify-center items-center">
          <form
            className="md:w-1/2 flex flex-col justify-center items-center"
            onSubmit={(e) => performLogin(e)}>
            <Typography className="w-full text-left" variant="h4">
              Welcome back
            </Typography>
            <Typography className="w-full text-left" variant="body1">
              Inser your details and start services
            </Typography>
            <div className="w-full mt-12">
              <TextField
                variant="outlined"
                label={t('entities.user.email')}
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                fullWidth
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              />
            </div>

            <div className="w-full mt-6">
              <TextField
                variant="outlined"
                label={t('entities.user.password')}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              />
            </div>
            <NavLink to={'login'} className="mt-2 w-full text-right text-primary-400 underline">
              <Typography variant="caption">Password dimenticata?</Typography>
            </NavLink>

            <div className="w-full mt-6">
              <Button type="submit" color="secondary" variant="contained" fullWidth>
                Sign in
              </Button>
            </div>
          </form>
        </div>
        <div className="md:w-1/2 bg-primary-100 flex flex-col justify-center items-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-40 w-auto" src="logo512.png" alt="themeColor" />
          </div>
        </div>
      </div>
    </>
  );
};
