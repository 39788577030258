import { ISector } from 'interfaces';
import { BaseService } from './BaseService';

class SkuMappingService extends BaseService<ISector> {
  constructor() {
    super('skus');
  }
  canDelete = true;
}

export const skuMappingService = new SkuMappingService();
