import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';
import { IPurchaseRequest } from 'interfaces';
import { NavLink, useNavigate } from 'react-router-dom';
import { purchaseRequestService } from 'services/api';
import { useTranslation } from 'react-i18next';
import CustomizedSteppers from 'components/common/RequestStatus';
import EditIcon from '@mui/icons-material/Edit';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { ButtonGroup, Button } from '@mui/material';
import SectionTitle from 'components/common/SectionTitle';

export const PurchaseRequestList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const translationPrefix = 'pages.purchase-requests.list';

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const pr = params.row as IPurchaseRequest;
        return (
          <a onClick={() => navigate(`view/${params.row.id}`)}>
            <SectionTitle>
              {t(`${translationPrefix}.request-description`, { id: pr.id })}
            </SectionTitle>
          </a>
        );
      }
    },
    {
      field: 'created_by',
      headerName: 'Proposer'
    },
    {
      field: 'status',
      width: 200,
      headerName: 'Status',
      renderCell: (props: GridRenderCellParams) => {
        const pr = props.row as IPurchaseRequest;
        return (
          <div className="w-full flex flex-row justify-end items-center">
            <div className="w-60">
              <CustomizedSteppers status={pr.status}></CustomizedSteppers>
            </div>
          </div>
        );
      }
    },
    {
      field: '',
      flex: 1,
      headerName: 'Actions',
      align: 'right',
      type: 'actions',
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            key={1}
            label="Modifica"
            icon={<EditIcon />}
            onClick={() => {
              navigate('view/' + params.row.id);
            }}
          />
          // <GridActionsCellItem key={2} label="Elimina" icon={<DeleteIcon />} onClick={} />
        ];
      }
    }
  ];

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div>
            <ButtonGroup>
              <NavLink to="new">
                <Button variant="outlined" color="primary" size="small">
                  {t(`${translationPrefix}.add-new`)}
                </Button>
              </NavLink>
            </ButtonGroup>
          </div>
        }>
        <EnhancedDataGrid columns={columns} service={purchaseRequestService} />
      </TenantPage>
    </>
  );
};

export default PurchaseRequestList;
