import { IExam } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { ExportableResourceService } from './ExportableResourceService';

class ExamService extends ExportableResourceService<IExam> {
  constructor() {
    super('exams');
  }

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };
}

export const examService = new ExamService();
