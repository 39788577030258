import { Button, TextField, Typography } from '@mui/material';
import { useAppDispatch } from 'app/store';
import { TenantPage } from 'components/common';
import { loadingActions } from 'features';
import { useTranslation } from 'react-i18next';
import { printLabel } from 'services/zebra';
import IncomingPicks from './IncomingPicks';

function PackagingDashboard() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const _printLabel = (event) => {
    dispatch(loadingActions.startLoading());
    event.preventDefault();
    setTimeout(() => {
      dispatch(loadingActions.stopLoading());
      printLabel('CODE', 'BATCH', '2023-10-11');
    }, 1000);
  };
  return (
    <TenantPage title={t('menu.packaging')}>
      <div className="flex h-full w-full">
        <div className="w-1/4">
          <IncomingPicks />
        </div>
        <div className="w-3/4 px-4">
          <form
            className="flex flex-col space-y-6 justify-center items-center h-full w-full"
            onSubmit={_printLabel}>
            <Typography variant="h2" align="center" className="text-primary-500" fontWeight={500}>
              Inserisci il codice del prodotto
            </Typography>
            <TextField
              id="outlined-basic"
              label="Outlined"
              variant="outlined"
              className="mb-6 w-full"
            />
            <Button type="submit" color="secondary" variant="contained" fullWidth className="my-6">
              Vai
            </Button>
          </form>
        </div>
      </div>
    </TenantPage>
  );
}

export default PackagingDashboard;
