import { useState, useEffect } from 'react';
import { IPurchaseOrder, IStatusOption } from 'interfaces';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { orderService } from 'services/api';
import CollapsableColumns from 'components/common/CollapsableColumns';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { OrderDetail } from 'components/inventory/PurchaseOrders';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { Button, ButtonGroup } from '@mui/material';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';

export const PurchaseOrderList = () => {
  const translationPrefix = 'pages.orders.list';
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [selectedOrder, setSelectedOrder] = useState<IPurchaseOrder>();
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);

  const editOrder = (reqId) => {
    navigate(`../purchase_requests/view/${reqId}`);
  };

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    orderService.options().then((res) => {
      setStatusOptions(res.actions.POST.status.choices);
    });
    dispatch(loadingActions.stopLoading());
  }, []);

  const downloadAttachment = (order) => {
    if (order?.id) {
      orderService.downloadAttachment(order.id);
    }
  };

  const statusColor = (statusString: string) => {
    switch (statusString) {
      case 'ACTIVE':
        return 'text-orange-500';
      case 'COMPLETED':
        return 'text-green-500';
      default:
        return 'text-red-500';
    }
  };

  const columns: GridColumns = [
    {
      field: 'ref',
      headerName: 'Ordine',
      flex: 1
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      type: 'singleSelect',
      valueOptions: statusOptions.map((type) => type.display_name),
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={statusColor(value)}>{t(`entities.order_head.${value}`)}</span>
          </div>
        );
      }
    },
    { field: 'supplier', headerName: t(`entities.order.supplier`), flex: 1 },
    { field: 'warehouse', headerName: t(`entities.order.warehouse`), flex: 1 },
    {
      field: 'total_amount',
      headerName: t(`entities.order.total_amount`),
      width: 100
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [
          <GridActionsCellItem
            key={1}
            icon={<EditIcon />}
            onClick={() => editOrder(params.row['from_request'])}
            label="Edit"
          />,
          <GridActionsCellItem
            key={2}
            icon={<RemoveRedEyeIcon />}
            onClick={() => navigate(`delivery?ref=${params.row.id}`)}
            label="View"
          />
        ];
        if (params.row.attachment) {
          actions.push(
            <GridActionsCellItem
              key={1}
              icon={<CloudDownloadIcon />}
              onClick={() => downloadAttachment(params.row)}
              label="View"
            />
          );
        }
        return actions;
      }
    }
  ];
  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <ButtonGroup size="small">
            <NavLink to="delivery?blameMode=true">
              <Button>{t(`${translationPrefix}.delivery`)}</Button>
            </NavLink>
          </ButtonGroup>
        }>
        <CollapsableColumns
          size="w-1/2"
          expand={!!selectedOrder}
          onClose={() => setSelectedOrder(null)}
          contentLeft={<EnhancedDataGrid columns={columns} service={orderService} />}
          contentRight={
            <div className="mt-6">{selectedOrder && <OrderDetail order={selectedOrder} />}</div>
          }
        />
      </TenantPage>
    </>
  );
};

export default PurchaseOrderList;
