import { IEquipment } from 'interfaces';
import { BaseService } from './BaseService';

class EquipmentService extends BaseService<IEquipment> {
  constructor() {
    super('equipments');
  }
}

export const equipmentService = new EquipmentService();
