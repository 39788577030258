import { ISector } from 'interfaces';
import { BaseService } from './BaseService';

class TypeService extends BaseService<ISector> {
  constructor() {
    super('types');
  }
}

export const typeService = new TypeService();
