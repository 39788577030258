import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography
} from '@mui/material';
import { PurchaseRequestStatus } from '../../../../constants';
import { Button } from '@mui/material';
import {
  contractService,
  fetchEntitySummary,
  productService,
  purchaseRequestService
} from 'services/api';
import { IOption, IPurchaseRequest, IPurchaseRequestRow, ISupplier, IWarehouse } from 'interfaces';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CustomizedSteppers from 'components/common/RequestStatus';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import TenantPage from 'components/common/TenantPage';
import PurchaseRequestLineForm from '../PurchaseRequestLineForm';
import { GridCellEditCommitParams, GridColumns, itIT, MuiEvent } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { v4 as uuidv4 } from 'uuid';
import { PurchaseLinesColumns } from 'components/common/enhanced/common-headers/request-lines';
import dayjs from 'dayjs';
import { purchaseRequestLineService } from 'services/api/PurchaseRequestLineService';
import toast from 'features/toast';

export const PurchaseRequest = () => {
  const { t } = useTranslation();
  const { purchaseRequestId } = useParams();
  const navigate = useNavigate();

  let translationPrefix = 'pages.purchase-requests.new';
  if (purchaseRequestId) translationPrefix = 'pages.purchase-requests.view';

  const { enqueueSnackbar } = useSnackbar();
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [requestPreview, setRequestPreview] = useState();

  const handleClosePreviewDialog = () => {
    setOpenPreviewDialog(false);
  };
  const [productToAdd, setProductToAdd] = useState<any>(null);
  const [contractToAdd, setContractToAdd] = useState<any>(null);
  const [, setWarehouseToAdd] = useState<any>(null);

  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [contractOptions, setContractOptions] = useState<IOption[]>([]);
  const [warehouseOptions, setWarehouseOptions] = useState<IOption[]>([]);

  const [purchaseRequest, setPurchaseRequest] = useState<IPurchaseRequest>({
    lines: [],
    id: null,
    status: null,
    created_by: 0
  });
  const [initialRequest, setInitialRequest] = useState<IPurchaseRequest>(purchaseRequest);

  // Constructor
  useEffect(() => {
    productService.getAllBaseInformation().then(setProductOptions);
    if (purchaseRequestId) {
      purchaseRequestService.get(+purchaseRequestId).then((pr) => {
        setPurchaseRequest(pr);
        setInitialRequest(pr);
      });
    }
  }, []);

  useEffect(() => {
    if (productToAdd) {
      contractService.getAllBaseInformation({ product: productToAdd.id }).then(setContractOptions);
    } else {
      setContractToAdd(null);
      setContractOptions([]);
    }
  }, [productToAdd]);

  useEffect(() => {
    if (contractToAdd) {
      fetchEntitySummary('warehouses', { contract: contractToAdd.id }).then((res) => {
        setWarehouseOptions(res);
      });
    } else {
      setWarehouseToAdd(null);
      setWarehouseOptions([]);
    }
  }, [contractToAdd]);

  // Edit Purchase req methods
  const reviewRequest = () => {
    if (purchaseRequest.id) {
      purchaseRequestService
        .preApproveRequest({
          id: purchaseRequest.id,
          lines: purchaseRequest.lines.map(mapLine)
        })
        .then(() => {
          enqueueSnackbar('Richiesta pre-approvata con successo', { variant: 'success' });
          location.reload();
        })
        .catch(() => {
          enqueueSnackbar('You are not allowed to perform this action.', { variant: 'error' });
        });
    }
  };

  const fulfillRequest = (sendEmail = false) => {
    if (purchaseRequestId) {
      purchaseRequestService
        .approveRequest(
          {
            id: +purchaseRequestId,
            lines: purchaseRequest.lines.map(mapLine)
          },
          sendEmail
        )
        .then(() => {
          location.reload();
          enqueueSnackbar('Richiesta evasa con successo', { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar('You are not allowed to perform this action.', { variant: 'error' });
        });
    }
  };

  const saveRequest = () => {
    if (!purchaseRequest.id) {
      purchaseRequestService
        .add({
          ...purchaseRequest,
          lines: purchaseRequest.lines.map(mapLine)
        })
        .then((res) => {
          enqueueSnackbar('Richiesta inserita con successo', { variant: 'success' });
          if (res.id) {
            setPurchaseRequest(res);
            setInitialRequest(res);
            navigate(`../view/${res.id}`);
          }
        });
    } else {
      purchaseRequestService
        .update(purchaseRequest.id, {
          ...purchaseRequest,
          lines: purchaseRequest.lines.map(mapLine)
        })
        .then((res) => {
          setPurchaseRequest(res);
          setInitialRequest(res);
          enqueueSnackbar('Richiesta aggiornata con successo', { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar('You are not allowed to perform this action.', { variant: 'error' });
        });
    }
  };

  // Utility methods
  const handleDelete = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);
    setPurchaseRequest((prevState) => {
      const lines = prevState.lines;
      const newLines = lines.map((obj, idx) => {
        if (index === idx) {
          return { ...obj, status: PurchaseRequestStatus.DELETED };
        }
        return obj;
      });
      return { ...prevState, lines: newLines };
    });
  };

  const approve = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.APPROVED);
  };

  const preApprove = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.PRE_APPROVED);
  };

  const reject = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.REJECTED);
  };

  const changeRowStatus = (index, newStatus) => {
    setPurchaseRequest((prevState) => {
      const lines = prevState.lines;
      const newLines = lines.map((obj, idx) => {
        return index === idx
          ? {
              ...obj,
              status: newStatus
            }
          : obj;
      });
      return { ...prevState, lines: newLines };
    });
  };

  const onSubmitRow = (values) => {
    // MULTIPLE ROWS
    if (values.programmed) {
      const startDate = dayjs(values.start_programmed);
      let currentDate = startDate,
        lastValidDay,
        lastValidDayOfWeek,
        lastValidWeek = 0,
        lastValidMonth;
      const endDate = dayjs(values.end_programmed);
      do {
        lastValidDay = currentDate.format('DD');
        lastValidDayOfWeek = currentDate.day();
        lastValidWeek += lastValidDayOfWeek === startDate.day() ? 1 : 0;
        lastValidMonth = currentDate.month();

        if (
          (values.rep_unit === 1 &&
            lastValidWeek % values.rep_frequency === 0 &&
            values.day_of_month == +lastValidDayOfWeek) ||
          (values.rep_unit === 2 &&
            (lastValidMonth - startDate.month()) % values.rep_frequency === 0 &&
            +values.day_of_month === +lastValidDay)
        ) {
          ((requested_shipment_date) => {
            setPurchaseRequest((prevState) => {
              return {
                ...prevState,
                lines: [
                  ...prevState.lines,
                  { ...values, id: uuidv4(), status: 1, requested_shipment_date, is_periodic: true }
                ]
              };
            });
          })(currentDate.format('YYYY-MM-DD'));
        }

        currentDate = currentDate.add(1, 'day');
      } while (currentDate.isBefore(endDate));
    } else {
      // SINGLE ROW
      setPurchaseRequest((prevState) => {
        return {
          ...prevState,
          lines: [...prevState.lines, { id: uuidv4(), status: 1, ...values }]
        };
      });
    }
  };

  function mapLine(item) {
    return {
      ...item,
      id: +item.id || null,
      product: item.product['code']
    };
  }

  const getOrderPreview = () => {
    if (purchaseRequestId) {
      purchaseRequestService
        .getOrderPreview({ ...purchaseRequest, id: +purchaseRequestId })
        .then((res) => {
          setRequestPreview(res);
          setOpenPreviewDialog(true);
        });
    }
  };

  const getOrderPreviewInnerContent = () => {
    return (
      requestPreview &&
      Object.keys(requestPreview)
        .filter((k) => k !== 'detail')
        .map((v, i) => {
          // Supplier
          const supplier = requestPreview[v];
          const supplierDetail = requestPreview[v]['detail'] as ISupplier;
          return (
            <div key={v + i} className="px-3 mb-3">
              <b>{supplierDetail['company_name']}</b>
              <ul>
                {Object.keys(supplier)
                  .filter((k) => k !== 'detail')
                  .map((k) => {
                    const warehouse = requestPreview[v][k];
                    const warehouseDetail = requestPreview[v][k]['detail'] as IWarehouse;

                    const lines = warehouse['lines'] as IPurchaseRequestRow[];

                    return (
                      <div key={warehouseDetail.id} className="px-3">
                        {warehouseDetail.description}
                        <ul className="px-3">
                          {lines.map((line) => (
                            <li key={warehouse['id'] + '-' + line.id}>
                              <b>{line.product}</b> - {line.quantity}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
              </ul>
            </div>
          );
        })
    );
  };

  const changesToSave = (): boolean => {
    return JSON.stringify(initialRequest) === JSON.stringify(purchaseRequest);
  };

  const columns: GridColumns = PurchaseLinesColumns(reject, approve, preApprove, handleDelete);

  const onEditCell = (params: GridCellEditCommitParams, event: MuiEvent) => {
    if (+params.id) {
      const payload = {};
      payload[params.field] = params.value;
      if (payload[params.field])
        purchaseRequestLineService.update(+params.id, { ...payload }).then(() => {
          toast.success(t('toasts.updated-cell'));
        });
    } else {
      setPurchaseRequest({
        ...purchaseRequest,
        lines: purchaseRequest.lines.map((line) => {
          if (line.id === params.id) {
            if (params.value instanceof Date) {
              line[params.field] = dayjs(params.value).format('YYYY-MM-DD');
            } else {
              line[params.field] = params.value;
            }
          }
          return line;
        })
      });
    }
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`, { id: purchaseRequest['id'] })}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex items-center justify-end">
            <div className="w-60">
              <CustomizedSteppers status={purchaseRequest.status}></CustomizedSteppers>
            </div>
            <ButtonGroup size="small" variant="outlined">
              <Button disabled={changesToSave()} onClick={saveRequest}>
                <Tooltip title={t('global.save')}>
                  <SaveIcon />
                </Tooltip>
              </Button>

              <Button disabled={!changesToSave()} onClick={reviewRequest}>
                {t(`pages.purchase-requests.new.pre-approve`)}
              </Button>

              {purchaseRequestId && (
                <Button disabled={!changesToSave()} color="success" onClick={getOrderPreview}>
                  {t('pages.purchase-requests.new.generate-order')}
                </Button>
              )}
            </ButtonGroup>
          </div>
        }>
        <Accordion className="bg-slate-50 my-8">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('pages.purchase-requests.new.add-element')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PurchaseRequestLineForm
              onSubmit={onSubmitRow}
              products={productOptions}
              setProduct={setProductToAdd}
              contracts={contractOptions}
              setContract={setContractToAdd}
              warehouses={warehouseOptions}
              setWarehouse={setWarehouseToAdd}
            />
          </AccordionDetails>
        </Accordion>
        {/* Item list */}
        <div>
          <DataGridPro
            density="compact"
            initialState={{
              pinnedColumns: {
                left: ['code', 'approved'],
                right: ['actions']
              }
            }}
            rows={purchaseRequest.lines
              .filter((row) => row.status !== PurchaseRequestStatus.DELETED)
              .map((l) => {
                return { id: uuidv4(), ...l, description: l.product['description'] };
              })}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            sx={{ border: 'none' }}
            autoHeight
            disableSelectionOnClick
            pagination
            columns={columns}
            onCellEditCommit={onEditCell}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
          />
        </div>

        <div>
          <Dialog
            open={openPreviewDialog}
            onClose={handleClosePreviewDialog}
            fullWidth
            maxWidth="md">
            <DialogTitle id="alert-dialog-title">{'Order preview'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p>{t('pages.purchase-requests.new.order-preview-description')}</p>
                {getOrderPreviewInnerContent()}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="w-full flex justify-between">
                <Button onClick={handleClosePreviewDialog}>{t('actions.cancel')}</Button>
                <div>
                  <Button className="me-2" onClick={() => fulfillRequest(false)}>
                    {t('actions.confirm')}
                  </Button>
                  <Button variant="contained" onClick={() => fulfillRequest(true)} autoFocus>
                    {t('actions.confirm-send-email')}
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </TenantPage>
    </>
  );
};

export default PurchaseRequest;
