import { IMovementReason } from 'interfaces';
import { BaseService } from './BaseService';

class MovementReasonService extends BaseService<IMovementReason> {
  constructor() {
    super('movement-types');
  }
}

export const movementReasonService = new MovementReasonService();
