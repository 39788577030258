import { GridColumns } from '@mui/x-data-grid-pro';
import { TenantPage } from 'components/common';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { dateCell } from 'components/common/enhanced/cells/date-cell';
import { imgCell } from 'components/common/enhanced/cells/img-cell';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import dayjs from 'dayjs';
import toast from 'features/toast';
import { useTranslation } from 'react-i18next';
import { productService, sellingShippingService } from 'services/api';
import { ISellingShipping } from '../models';
import SellingShippingForm from './SellingShippingForm';

function SellingShippingList() {
  const { t } = useTranslation();

  const _columns: GridColumns = [];
  [
    { label: 'id', width: 50 },
    {
      label: 'courier',
      tPrefix: 'entities.selling-shipping',
      width: 150,
      render: imgCell('/couriers', '.png')
    },

    { label: 'code', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'description', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'dimension', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'items', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'label', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'parcel_id', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'resourcetype', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'selling_order', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'status', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'track_id', tPrefix: 'entities.selling-shipping', width: 200 },
    { label: 'weight', tPrefix: 'entities.selling-shipping', width: 200 }
  ].forEach((field) =>
    _columns.push({
      field: field.label,
      headerName: t(`${field.tPrefix || 'global'}.${field.label}`),
      width: field.width,
      renderCell: field.render || baseCell
    })
  );

  const saveShip = (values: any) => {
    sellingShippingService
      .add({ ...values, resourcetype: 'BartoliniSellingShipping' })
      .then(() => toast.success('salvato'));
  };

  return (
    <TenantPage title={t('menu.shipping')}>
      <div>
        <EnhancedDataGrid columns={_columns} service={sellingShippingService} />
        {/* <SellingShippingForm onSubmit={saveShip} shipping={{}} /> */}
      </div>
    </TenantPage>
  );
}

export default SellingShippingList;
