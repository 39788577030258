import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip
} from '@mui/material';
import {
  headquarterService,
  locationService,
  tenantSettingsService,
  warehouseService
} from 'services/api';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TenantPage from 'components/common/TenantPage';

import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';

export const Topology = () => {
  const translationPrefix = 'pages.topology.main';
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [entityToAdd, setEntityToAdd] = useState({
    name: 'laboratory',
    code: '',
    description: '',
    callback: (entity) => null
  });
  const [selected, setSelected] = useState({
    headquarter: null,
    warehouse: null,
    location: null
  });
  const [headquarters, setHeadquarters] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [locations, setLocations] = useState([]);

  const loadWarehouses = (hqId) => {
    setLocations([]);
    setSelected({ ...selected, headquarter: hqId });
    warehouseService.getAllBaseInformation({ headquarter: hqId }).then((res) => setWarehouses(res));
  };
  const loadLocations = (wId) => {
    setSelected({ ...selected, warehouse: wId });
    locationService.getAllBaseInformation({ warehouse: wId }).then((res) => setLocations(res));
  };

  // Add entity
  const newLaboratory = () => {
    setEntityToAdd({
      name: 'laboratory',
      code: '',
      description: '',
      callback: (entity) => {
        headquarterService
          .add({
            id: null,
            code: entity.code,
            description: entity.description
          })
          .then((res) => {
            setHeadquarters([...headquarters, res]);
          });
      }
    });
    setOpen(true);
  };

  const newWarehouse = () => {
    navigate('warehouse/new');
  };
  const newLocation = () => {
    setEntityToAdd({
      name: 'location',
      code: '',
      description: '',
      callback: (entity) => {
        locationService
          .add({
            id: null,
            code: entity.code,
            description: entity.description,
            warehouse: selected.warehouse
          })
          .then((res) => {
            setLocations([...locations, res]);
          });
      }
    });
    setOpen(true);
  };

  const saveEntity = () => {
    entityToAdd.callback(entityToAdd);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    headquarterService.getAllBaseInformation().then((res) => setHeadquarters(res));
  }, []);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex items-center justify-between">
            <ButtonGroup className="mr-4 space-x-2">
              <Tooltip title={t(`import-modal.title`)}>
                <ImportFileUploader service={tenantSettingsService} />
              </Tooltip>

              <Tooltip title={t(`${translationPrefix}.to-tree-view`)}>
                <NavLink to="tree">
                  <Button size="small">
                    <AccountTreeIcon />
                  </Button>
                </NavLink>
              </Tooltip>
            </ButtonGroup>
            <ButtonGroup>
              <Button size="small" onClick={newLaboratory}>
                <AddIcon></AddIcon>
                {t(`global.laboratory`)}
              </Button>
              <Button size="small" onClick={newWarehouse} disabled={!selected.headquarter}>
                <AddIcon></AddIcon>
                {t(`global.warehouse`)}
              </Button>
              <Button size="small" onClick={newLocation} disabled={!selected.warehouse}>
                <AddIcon></AddIcon>
                {t(`global.location`)}
              </Button>
            </ButtonGroup>
          </div>
        }>
        <div className="flex">
          {[
            { key: 'headquarters', data: headquarters, loadMethod: loadWarehouses },
            { key: 'warehouse', data: warehouses, loadMethod: loadLocations },
            { key: 'location', data: locations, loadMethod: null }
          ].map((entity) => (
            <div className="w-1/3 border-r border-slate-200" key={entity.key}>
              <h3 className="font-bold border-b border-slate-200">{entity.key}</h3>
              {entity.data.map((item) => (
                <ListItemButton selected={item.id === selected[entity.key]} key={item.id}>
                  <ListItem
                    disableGutters
                    onClick={() => entity.loadMethod(item.id)}
                    onDoubleClick={() => navigate(`${entity.key}/${item.id}`)}
                    secondaryAction={
                      <div>
                        {entity.loadMethod && (
                          <IconButton onClick={() => entity.loadMethod(item.id)}>
                            <ArrowForwardIosIcon />
                          </IconButton>
                        )}
                      </div>
                    }>
                    <div>
                      <ListItemText primary={item.description} secondary={item.code} />
                      <div className="absolute -top-2 right-4 flex items-center">
                        <span className="mr-2">{item.children_count || 0}</span>
                        <div
                          className={`h-4 w-4 rounded-full ${
                            item.children_count > 0 ? 'bg-primary-700' : 'bg-primary-300'
                          }`}></div>
                      </div>
                    </div>
                  </ListItem>
                </ListItemButton>
              ))}
            </div>
          ))}
        </div>
        <div>
          <Dialog open={open}>
            <DialogTitle>
              {t(`${translationPrefix}.add-new-modal.title`, {
                entityName: t(`global.${entityToAdd.name}`)
              })}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t(`${translationPrefix}.add-new-modal.body`, {
                  entityName: t(`global.${entityToAdd.name}`)
                })}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="code"
                label={t(`global.${entityToAdd.name}`)}
                fullWidth
                variant="standard"
                value={entityToAdd.code}
                onChange={(e) => setEntityToAdd({ ...entityToAdd, code: e.target.value })}
              />
              <TextField
                autoFocus
                margin="dense"
                id="description"
                label={t(`global.${entityToAdd.name}`)}
                fullWidth
                variant="standard"
                value={entityToAdd.description}
                onChange={(e) => setEntityToAdd({ ...entityToAdd, description: e.target.value })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annulla</Button>
              <Button onClick={saveEntity}>Aggiungi</Button>
            </DialogActions>
          </Dialog>
        </div>
      </TenantPage>
    </>
  );
};

export default Topology;
