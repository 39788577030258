import { SyntheticEvent } from 'react';
import { useField } from 'react-final-form';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { IOption } from 'interfaces';

export const MultipleAutocompleteAdapter = ({
  input: { name },
  options,
  optionLabel,
  optionValue,
  initValue,
  setValue,
  freeSolo,
  ...rest
}: any) => {
  const { input, meta } = useField(name);

  const optLabel = optionLabel ?? 'code';
  const optValue = optionValue ?? 'id';

  const onChange = (e: SyntheticEvent, values) => {
    const newValues = [];
    if (values) {
      values.forEach((value) => {
        if (typeof value === 'string') {
          const x = {};
          x[optLabel] = value;
          x[optValue] = value;
          newValues.push(value ?? '');
        } else {
          newValues.push(value[optValue] ?? '');
        }
      });
      input.onChange(newValues);
    }
  };

  const getOptionLabel = (option: IOption | string) => {
    switch (typeof option) {
      case 'string':
        return option;

      default:
        return option[optLabel];
    }
  };

  const disabled = () => {
    if (freeSolo === true) return false;
    return !options.length;
  };

  return (
    <Autocomplete
      disablePortal
      multiple={true}
      freeSolo={freeSolo === true}
      getOptionLabel={(option) => option[optLabel] ?? ''}
      options={options}
      defaultValue={initValue ?? []}
      disabled={disabled()}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option[optValue] === value[optValue]}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            key={`${option}-${index}`}
            variant="outlined"
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props}>
            {option[optLabel]}
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...rest}
            helperText={meta.touched ? meta.error : ''}
            error={meta.error && meta.touched}
            fullWidth
          />
        );
      }}
    />
  );
};
