import { Button, ButtonGroup, Card, CardContent } from '@mui/material';
import { contractLineService, contractService } from 'services/api';
import { IContract, IContractLine, IOption } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import ContractLineForm from '../ContractLineForm';
import ContractHeaderForm from '../ContractHeaderForm';
import ContractInsights from '../ContractInsights';
import FileUploader from 'components/common/file-uploader';
import { DataGrid } from '@mui/x-data-grid';
import { ContractLinesColumns } from 'components/common/enhanced/common-headers/contract-lines';
import EntityHistory from 'components/widgets/EntityHistory';
import SectionTitle from 'components/common/SectionTitle';

const downloadableFields = ['commercial_bid', 'bid_approval'];

export const EditContract = () => {
  const translationPrefix = 'pages.contract.edit';

  const { contractId } = useParams();
  const [contract, setContract] = useState<IContract>();
  const [contractLines, setContractLines] = useState<IContractLine[]>([]);
  const [contractLinesToDelete, setContractLinesToDelete] = useState<number[]>([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IContractLine | null>(null);

  const [products, setProducts] = useState<IOption[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    // Load current contract
    contractService.get(+contractId).then(setContract);
    contractLineService
      .getAllPaginated(1000, 0, { contract_header: contractId })
      .then((res) => setContractLines(res.results));
    dispatch(loadingActions.stopLoading());
  }, []);

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    contractService.update(+contractId, values).then((res) => {
      if (res.id) {
        contractService.removeProducts(contractLinesToDelete, res.id);
        contractService
          .assignProducts(
            contractLines.map((cl) => {
              return { ...cl, product: cl.product['id'] };
            }),
            res.id
          )
          .then((res) => {
            enqueueSnackbar('Contratto aggiunto con successo');
          });
      }
    });
    dispatch(loadingActions.stopLoading());
  };

  const onLineSubmit = async (values) => {
    const lineToAdd: IContractLine = {
      id: Math.random() * 1000000,
      product: values.product,
      unit_price: values.unit_price,
      initial_available_units: values.initial_available_units,
      available_units: values.initial_available_units,
      used_units: 0,
      blocked_units: 0,
      initial_discounted_units: values.initial_discounted_units,
      available_discounted_units: values.initial_discounted_units,
      used_discounted_units: 0,
      blocked_discounted_units: 0,
      supplier: '',
      warehouse: ''
    };

    setContractLines([...contractLines, lineToAdd]);
  };

  const removeLine = (idx) => {
    setContractLines((lines) => {
      setContractLinesToDelete([...contractLinesToDelete, lines[idx].id]);
      return lines.filter((l, i) => i !== idx);
    });
  };

  const contractLineColumns = ContractLinesColumns(
    translationPrefix,
    (line) => {
      const i = contractLines.findIndex((cl) => cl.id === line.id);
      removeLine(i);
    },
    (line) => {
      const i = contractLines.findIndex((cl) => cl.id === line.id);
      editLine(contractLines[i]);
    }
  );

  const editLine = (cl) => {
    setSelectedItem(cl);
    setOpenDialog(true);
  };

  const onSubmitEdit = async (values) => {
    dispatch(loadingActions.startLoading());
    contractLineService
      .update(selectedItem.id, { ...values, product: values.product.id })
      .then(() => {
        enqueueSnackbar('Contract line updated successfully', { variant: 'success' });
        contractLineService
          .getAllPaginated(1000, 0, { contract_header: contractId })
          .then((res) => setContractLines(res.results));
      });
    dispatch(loadingActions.stopLoading());
    setSelectedItem(null);
    setOpenDialog(false);
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`, { id: contract?.id })}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex justify-end align-center">
          <ContractInsights contract={contract} lines={contractLines} />
          <ButtonGroup variant="outlined" size="small">
            <Button type="submit" form="contractForm">
              <SaveIcon />
            </Button>
          </ButtonGroup>
        </div>
      }>
      <SectionTitle>{t(`pages.contract.new.header-section`)}</SectionTitle>

      {contract && (
        <ContractHeaderForm
          contract={contract}
          onSubmit={onSubmit}
          onProductsUpdate={setProducts}
        />
      )}

      <div className="py-4 w-full">
        <SectionTitle>{t(`global.attachments`)}</SectionTitle>
        {contract && (
          <Card variant="outlined">
            <CardContent>
              <div className="flex flex-wrap">
                {downloadableFields.map((field, i) => (
                  <div key={i} className={`w-1/${downloadableFields.length}`}>
                    <span>{t(`entities.contract.${field}`)}:</span>
                    <FileUploader
                      service={contractService}
                      entityId={contract.id}
                      attachmentName={field}
                      canDownload={contract[field]}
                    />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      <div className="py-5">
        <hr className="pb-5" />
        <SectionTitle>{t(`pages.contract.new.line-section`)}</SectionTitle>
      </div>
      <ContractLineForm
        onSubmit={onLineSubmit}
        products={products.filter((p) => !contractLines.some((line) => +line.product === +p.id))}
      />
      <div className="flex flex-wrap">
        <DataGrid
          autoHeight
          disableSelectionOnClick
          rows={contractLines}
          columns={contractLineColumns}
          pageSize={20}
        />
      </div>
      <div className="w-full md:w-1/2 mt-4">
        {contract && (
          <Card variant="outlined">
            <CardContent>
              <EntityHistory service={contractService} entityId={contract.id} />
            </CardContent>
          </Card>
        )}
      </div>
    </TenantPage>
  );
};

export default EditContract;
