import { TenantPage } from 'components/common';
import { TransportDocumentColumns } from 'components/common/enhanced/common-headers/transport-document';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { transportDocumentService } from 'services/api';
import { uniqueId } from 'lodash';

export function TransportDocumentList() {
  const { t } = useTranslation();
  const prefix = 'pages.ddt.list';
  const datagridRefresh = useRef(null);

  const columns = TransportDocumentColumns(datagridRefresh.current);
  const filters = [];
  const initialParams = {};
  filters.push({
    id: uniqueId(),
    columnField: 'closed',
    operatorValue: 'is',
    value: 'false'
  });
  initialParams['closed'] = 'false';

  return (
    <TenantPage title={t(`${prefix}.title`)} subtitle={t(`${prefix}.subtitle`)}>
      <EnhancedDataGrid
        columns={columns}
        initialFilterItems={filters}
        initialParams={initialParams}
        service={transportDocumentService}
        refresh={datagridRefresh}></EnhancedDataGrid>
    </TenantPage>
  );
}

export default TransportDocumentList;
