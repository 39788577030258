import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DataGridCell from 'components/common/DataGridCell';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { filterOperators } from '../../../../constants';
import { Dayjs } from 'dayjs';
import { productService } from 'services/api';
import { NavLink } from 'react-router-dom';
import toast from 'features/toast';
import { baseCell } from '../cells/base-cell';
import { currencyFormatter } from 'helpers/currency-formatter';

export function ConsumptionsColumns(startDate: Dayjs, endDate: Dayjs): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.movement';
  const monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];
  const columns = [
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: (params: GridRenderCellParams<string>) => (
        <NavLink to={`../products/${params.row.product_id}`}>
          <b className="text-primary-600">{params.value}</b>
        </NavLink>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        if (params.row.product_id) {
          return [
            <GridActionsCellItem
              key={1}
              icon={<AutoFixHighIcon />}
              onClick={() => {
                productService
                  .update(params.row.product_id, {
                    understock_threshold: params.row.avg
                  })
                  .then(() =>
                    toast.info(`Soglia minima di ${params.row.product} aggiornata con successo`)
                  );
              }}
              label="Update minimum"
            />
          ];
        } else {
          return [];
        }
      }
    },
    {
      field: 'unit_price',
      type: 'string',
      headerName: t(`${translationPrefix}.unit_price`),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b className="text-primary-600"> {currencyFormatter(params.value)} </b>
      )
    },
    {
      field: 'avg',
      type: 'number',
      headerName: t(`${translationPrefix}.avg_quantity`),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b className="text-primary-600"> {params.value} </b>
      )
    },
    {
      field: 'tot',
      type: 'number',
      headerName: t(`${translationPrefix}.total`),
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b className="text-primary-600"> {params.value} </b>
      )
    },
    {
      field: 'description',
      type: 'string',
      headerName: t(`global.description`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      type: 'string',
      headerName: t(`global.supplier`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'notes',
      type: 'string',
      headerName: t(`global.notes`),
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'category',
      type: 'string',
      headerName: t(`global.category`),
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    }
  ];

  if (endDate && startDate) {
    for (
      let currentDate = startDate;
      endDate.isAfter(currentDate);
      currentDate = currentDate.add(1, 'month')
    ) {
      const year = currentDate.year();
      const month = currentDate.month();

      columns.push({
        field: `m_${month}__y_${year}`,
        type: 'number',
        headerName: `${t(`months.${monthNames[month]}`)} ${year}`,
        filterable: false,
        sortable: false,
        width: 100,
        renderCell: (params: GridRenderCellParams<string>) => (
          <DataGridCell content={params.value} />
        )
      });
    }
  }

  return columns;
}
