import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';

const columns: GridColDef[] = [
  {
    field: 'lastName',
    headerName: 'Prodotto',
    width: 150,
    editable: true
  },
  {
    field: 'direction',
    headerName: 'Direzione',
    type: 'number',
    width: 110,
    editable: true
  },
  {
    field: 'quantity',
    headerName: 'Quantità',
    description: 'This column has a value getter and is not sortable.',
    sortable: true,
    width: 160
  }
];

const rows = [
  { id: 1, lastName: 'Product 1', direction: 'In', quantity: 7 },
  { id: 2, lastName: 'Product 2', direction: 'In', quantity: 1 },
  { id: 3, lastName: 'Product 7', direction: 'Out', quantity: 10 },
  { id: 4, lastName: 'Product 1', direction: 'In', quantity: 4 },
  { id: 5, lastName: 'Product 3', direction: 'Out', quantity: 2 },
  { id: 6, lastName: 'Product 4', direction: 'In', quantity: 10 },
  { id: 7, lastName: 'Product 6', direction: 'In', quantity: 10 },
  { id: 8, lastName: 'Product 1', direction: 'In', quantity: 10 },
  { id: 9, lastName: 'Product 3', direction: 'In', quantity: 10 }
];

function MovementsWidget() {
  return (
    <Box sx={{ width: '100%' }}>
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="h6" fontWeight={800}>
            Movimenti
          </Typography>
          <Typography variant="body1">Anteprima movimentazioni recenti</Typography>
        </div>
        <NavLink to={'movements'}>
          <Button color="secondary" variant="contained" size="small">
            Vai alla pagina
          </Button>
        </NavLink>
      </div>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 15]}
        disableSelectionOnClick
      />
    </Box>
  );
}

export default MovementsWidget;
