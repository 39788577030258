import { Alert, Button, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import {
  composeValidators,
  mustBeNumber,
  required,
  validMonthDay,
  validWeekDay
} from 'helpers/validators';
import { IOption } from 'interfaces';
import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

interface PurchaseRequestLineFormProps {
  onSubmit: any;
  products: IOption[];
  setProduct: any;
  contracts: IOption[];
  setContract: any;
  warehouses: IOption[];
  setWarehouse: any;
}

const monthWeekOptions = [
  { id: 1, code: 'Settimana' },
  { id: 2, code: 'Mese' }
];

const PurchaseRequestLineForm = (props: PurchaseRequestLineFormProps) => {
  const { onSubmit, products, setProduct, contracts, setContract, warehouses, setWarehouse } =
    props;

  const translationPrefix = 'pages.purchase-requests.new';
  const { t } = useTranslation();
  const [programmed, setProgrammed] = useState(false);
  const [frequencyOption, setFrequencyOption] = useState(monthWeekOptions.find((o) => o.id === 1));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProgrammed(event.target.checked);
  };

  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    setProduct(selectedProduct);
  }, [selectedProduct]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full p-2">
        <ReactFinalForm
          onSubmit={(values) => onSubmit({ ...values, programmed })}
          formId="addPurchaseRequestLine"
          hideControls={true}>
          <div className="flex flex-wrap mb-2 w-full">
            <div className="px-2 pb-2 w-full">
              <b className="text-primary-600">{t(`${translationPrefix}.product-info`)}</b>
            </div>
            {selectedProduct && selectedProduct['order_notes'] !== '' && (
              <div className="mb-4 w-full">
                <Alert severity="info">{selectedProduct['order_notes']}</Alert>
              </div>
            )}
            <div className="w-1/3 mb-4 pr-4">
              <Field
                name="product"
                component={AutocompleteAdapter}
                options={products}
                label="Product"
                optionLabel="description"
                type="text"
                validate={composeValidators(required)}
                setValue={setSelectedProduct}
                required
                submitObject
              />
            </div>
            <div className="w-1/3 mb-4 pr-4">
              <Field
                name="contract"
                component={AutocompleteAdapter}
                options={contracts}
                label="Contract"
                placeholder="Contract"
                type="text"
                optionValue="code"
                validate={composeValidators(required)}
                setValue={setContract}
                required
              />
            </div>
            <div className="w-1/3 mb-4">
              <Field
                name="warehouse"
                component={AutocompleteAdapter}
                options={warehouses}
                label="Warehouse"
                placeholder="Warehouse"
                type="text"
                optionValue="code"
                validate={composeValidators(required)}
                setValue={setWarehouse}
                required
              />
            </div>
            <div className="grid grid-cols-12 px-2 pb-2 w-full">
              <b className="text-primary-600 col-start-1 col-end-6">
                {t(`${translationPrefix}.shipment`)}
              </b>
              <div className="text-primary-600 col-start-7 col-end-12">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={programmed}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={
                      <Typography color="text-primary-600">
                        <b>Ordine programmato</b>
                      </Typography>
                    }
                  />
                </FormGroup>
              </div>
            </div>
            {!programmed && (
              <div className="w-3/4 mb-4 pr-4">
                <Field
                  name="requested_shipment_date"
                  component={DatePickerAdapter}
                  label="Requested shipment date"
                  type="date"
                />
              </div>
            )}
            <div className="w-1/4 mb-4">
              <Field
                name="quantity"
                component={TextFieldAdapter}
                label="Quantity"
                placeholder="Quantity"
                type="number"
                validate={composeValidators(required, mustBeNumber)}
              />
            </div>
            {programmed && (
              <>
                <div className="w-1/6 mb-4 pl-4">
                  <Field
                    name="rep_frequency"
                    component={TextFieldAdapter}
                    label="Ripeti ogni"
                    type="number"
                    validate={composeValidators(mustBeNumber)}
                  />
                </div>
                <div className="w-5/12 mb-4 pl-4">
                  <Field
                    name="rep_unit"
                    component={AutocompleteAdapter}
                    options={monthWeekOptions}
                    setValue={setFrequencyOption}
                    label="Mese/settimana"
                    type="text"
                  />
                </div>
                <div className="w-1/6 mb-4 pl-4">
                  <Field
                    name="day_of_month"
                    component={TextFieldAdapter}
                    label={frequencyOption.id === 1 ? 'Giorno della settimana' : 'Giorno del mese'}
                    type="number"
                    placeholder="1"
                    validate={
                      frequencyOption.id === 1
                        ? composeValidators(validWeekDay)
                        : composeValidators(validMonthDay)
                    }
                  />
                </div>
                <div className="w-1/2 mb-4">
                  <Field
                    name="start_programmed"
                    component={DatePickerAdapter}
                    label="Start"
                    type="date"
                  />
                </div>
                <div className="w-1/2 mb-4 pl-4">
                  <Field
                    name="end_programmed"
                    component={DatePickerAdapter}
                    label="End"
                    type="date"
                  />
                </div>
              </>
            )}
            <div className="px-2 pb-2 w-full">
              <b className="text-primary-600">{t(`${translationPrefix}.other`)}</b>
            </div>
            <div className="w-full mb-4">
              <Field name="notes" component={TextFieldAdapter} label="Note" type="text" />
            </div>
            <div className="w-full mb-4 py-auto flex flex-wrap justify-end">
              <Button
                type="submit"
                form="addPurchaseRequestLine"
                variant="outlined"
                className="w-full">
                {t(`${translationPrefix}.add_line`)}
              </Button>
            </div>
          </div>
        </ReactFinalForm>
      </div>
    </div>
  );
};

export default PurchaseRequestLineForm;
