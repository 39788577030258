import { ITenantSettings } from 'interfaces';
import { SignJWT } from 'jose';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { tenantSettingsService } from 'services/api';

const QuestionViewer = () => {
  const { questionId } = useParams();

  const [iframeUrl, setIframeUrl] = useState('');
  const [settings, setSettings] = useState<ITenantSettings>();

  let metabaseUrl = null;
  let secretKey = null;

  useEffect(() => {
    tenantSettingsService.get(1).then(setSettings);
  }, []);

  useEffect(() => {
    if (settings && settings.metabase_token && questionId) {
      metabaseUrl = process.env.REACT_APP_METABASE_SITE_URL;
      secretKey = new TextEncoder().encode(settings.metabase_token);
      const payload = {
        resource: { question: +questionId },
        params: {}
      };

      new SignJWT(payload)
        .setProtectedHeader({ alg: 'HS256' })
        .setExpirationTime('1h')
        .sign(secretKey)
        .then((token) => {
          setIframeUrl(
            metabaseUrl +
              '/embed/question/' +
              token +
              '#theme=transparent&bordered=true&titled=false'
          );
        });
    }
  }, [settings, questionId]);

  return (
    <div className="h-screen">
      <iframe
        className="clickup-embed clickup-dynamic-height"
        src={iframeUrl}
        width="100%"
        height="100%"
        style={{ background: 'transparent', border: '0' }}></iframe>
    </div>
  );
};

export default QuestionViewer;
