import { ROLE_SUPER_ADMIN, TenantRoles } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GridRenderCellParams, GridColumns, useGridApiContext, GridColDef } from '@mui/x-data-grid';

function SelectEditInputCell(props: GridRenderCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({ id, field, value: [event.target.value] });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select value={value} onChange={handleChange} size="small" sx={{ height: 1 }} native autoFocus>
      {TenantRoles.map((role) => (
        <option key={role} value={[role]}>
          {role}
        </option>
      ))}
    </Select>
  );
}

const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {
  return <SelectEditInputCell {...params} />;
};

export function UserColumns(): GridColumns | GridColDef[] {
  const { t } = useTranslation('');

  const columns: GridColumns | GridColDef[] = [];
  ['username', 'first_name', 'last_name', 'email'].forEach((field) => {
    columns.push({
      field: field,
      width: 250,
      headerName: t(`entities.user.${field}`),
      editable: true
    });
  });

  columns.push({
    field: 'groups',
    width: 250,
    headerName: 'Role',
    renderEditCell: renderSelectEditInputCell
  });

  return columns;
}
