import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { italianDistricts } from '../../../constants';
import { required } from 'helpers/validators';
import { IOption, IWarehouse } from 'interfaces';
import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { headquarterService, sectorService } from 'services/api';

interface LaboratoryFormParams {
  warehouse: IWarehouse;
  onSubmit: any;
}
function WarehouseForm(params: LaboratoryFormParams) {
  const { t } = useTranslation();

  const [headquarters, setHeadquarters] = useState<IOption[]>([]);

  useEffect(() => {
    headquarterService.getAllBaseInformation().then(setHeadquarters);
  }, []);

  const { warehouse, onSubmit } = params;
  return (
    <div>
      {warehouse && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!warehouse.id}>
          <div className="flex flex-wrap">
            <div className="w-full mb-4 md:w-1/3 pr-4">
              <Field
                name="code"
                initialValue={warehouse.code}
                component={TextFieldAdapter}
                label={t('global.code')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full mb-4 md:w-2/3">
              <Field
                name="description"
                initialValue={warehouse.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full mb-4 md:w-2/3">
              <Field
                name="name"
                initialValue={warehouse.name}
                component={TextFieldAdapter}
                label={t('global.name')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full mb-4 md:w-1/2 pr-4">
              <Field
                name="address_line_1"
                initialValue={warehouse.address_line_1}
                component={TextFieldAdapter}
                label={t('entities.warehouse.address_line_1')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/2 pr-4">
              <Field
                name="address_line_1"
                initialValue={warehouse.address_line_2}
                component={TextFieldAdapter}
                label={t('entities.warehouse.address_line_1')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4 pr-4">
              <Field
                name="city"
                component={AutocompleteAdapter}
                initValue={italianDistricts.find((s) => s.code === warehouse.city)}
                options={italianDistricts}
                optionValue={'code'}
                label={t('entities.warehouse.city')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4">
              <Field
                name="postal_code"
                initialValue={warehouse.postal_code}
                component={TextFieldAdapter}
                label={t('entities.warehouse.postal_code')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/2 pr-4">
              <Field
                name="country"
                initialValue={warehouse.address}
                component={TextFieldAdapter}
                label={t('entities.warehouse.address')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4 pr-4">
              <Field
                name="address_2"
                initialValue={warehouse.address_2}
                component={TextFieldAdapter}
                label={t('entities.warehouse.address_2')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/4">
              <Field
                name="phone_number"
                initialValue={warehouse.phone_number}
                component={TextFieldAdapter}
                label={t('entities.warehouse.phone_number')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-2/3 pr-4">
              <Field
                name="additional_details"
                initialValue={warehouse.additional_details}
                component={TextFieldAdapter}
                label={t('global.additional_details')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/3">
              <Field
                name="opening_hours"
                initialValue={warehouse.opening_hours}
                component={TextFieldAdapter}
                label={t('global.opening_hours')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/2 pr-4">
              <Field
                name="contact"
                initialValue={warehouse.contact}
                component={TextFieldAdapter}
                label={t('global.contact')}
                type="text"
              />
            </div>
            <div className="w-full mb-4 md:w-1/2">
              <Field
                name="contact_2"
                initialValue={warehouse.contact_2}
                component={TextFieldAdapter}
                label={t('global.contact_2')}
                type="text"
              />
            </div>
            <div className="w-full mb-4">
              <Field
                name="cc"
                initialValue={warehouse.cc}
                component={TextFieldAdapter}
                label={t('entities.warehouse.cc')}
                placeholder="mario.verdi@mail.it; francesco.bianchi@mail.it; franco.rossi@mail.it"
                type="text"
              />
            </div>
            {/* <div className="w-full mb-4">
              <Field
                name="notes"
                initialValue={warehouse.notes}
                component={TextFieldAdapter}
                label={t('global.notes')}
                type="text"
              />
            </div> */}
            <div className="w-full mb-4 my-4">
              {headquarters && (
                <Field
                  name="headquarter"
                  component={AutocompleteAdapter}
                  initValue={headquarters.find((s) => s.id === warehouse.headquarter)}
                  defaultValue={warehouse.headquarter}
                  options={headquarters}
                  optionlabel="id"
                  label={t('global.headquarters')}
                  type="text"
                />
              )}
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default WarehouseForm;
