import { IMedicalService } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class MedicalServiceService extends BaseService<IMedicalService> {
  constructor() {
    super('medical-services');
  }

  importFromFile = async (importFile: File, attachmentName = 'import_file') => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/import-from-file/`, {
      [attachmentName]: importFile
    });
    return res.data;
  };

  generateLines = async (values: Record<string, unknown>) => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/generate-lines/`, values);
    return res.data;
  };
}

export const medicalServiceService = new MedicalServiceService();
