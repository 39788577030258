import { IUser } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class UserService extends BaseService<IUser> {
  constructor() {
    super('users');
  }

  getAll = async () => {
    const res = await axiosPrivate.get<IUser[]>(`${this.modulePrefix}/`);
    return res.data;
  };

  // Custom methods
  registerUser = async (entity: IUser) => {
    const res = await axiosPrivate.post(`register_user`, entity);
    return res.data;
  };

  selectWarehouse = async (warehouse: string) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/select-warehouse/`, {
      warehouse
    });
    return res.data;
  };
}

export const userService = new UserService();
