import { useEffect, useState } from 'react';
import { useNavigate, createSearchParams, NavLink } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'app/store';
import { loadingActions } from 'features';
import TenantPage from 'components/common/TenantPage';
import {
  movementService,
  productService,
  stockQuantityService,
  warehouseService
} from 'services/api';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { Field } from 'react-final-form';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { IOption, IStockData } from 'interfaces';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid';
import { filterOperators } from '../../../../constants';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import _ from 'lodash';
import StockChart from './../chart/StockChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SectionTitle from 'components/common/SectionTitle';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import DataGridCell from 'components/common/DataGridCell';

export const StockQuantities = () => {
  const translationPrefix = 'pages.stock_quantity.list';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [products, setProducts] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const [movements, setMovements] = useState<IStockData[]>([]);

  const movementsDetail = (params) => {
    const filteredParams = _.omitBy(params, _.isEmpty);
    navigate({
      pathname: '../report/stock/detail/',
      search: `${createSearchParams(filteredParams)}`
    });
  };

  const columns: GridColumns = [
    {
      field: 'product__code',
      headerName: t(`global.product`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'unit_price',
      type: 'string',
      headerName: t(`global.price`),
      filterable: false,
      sortable: false,
      width: 100,
      valueGetter: (params) => params.row.product__contract_lines__unit_price,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={currencyFormatter(params.value)} />
      )
    },
    {
      field: 'total_price',
      type: 'string',
      headerName: t(`global.total_price`),
      filterable: false,
      sortable: false,
      width: 100,
      valueGetter: (params) =>
        params.row.product__contract_lines__unit_price * params.row.total_quantity,
      renderCell: (params: GridRenderCellParams<string>) => (
        <b className="text-primary-600"> {currencyFormatter(params.value)} </b>
      )
    },
    {
      field: 'category',
      headerName: t(`global.category`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 300,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'warehouse__code',
      headerName: t(`global.warehouse`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 250,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'total_quantity',
      headerName: t(`entities.stock_quantity.total_quantity`),
      width: 150,
      type: 'number',
      renderCell: (params: GridRenderCellParams<string>) => {
        const understock = params.row.understock;
        const overstock = params.row.overstock;

        return (
          <div>
            {params.value}
            {params.value < understock && (
              <Tooltip title={t('alerts.understock', { value: understock })} placement="top">
                <TrendingDownIcon color="error" />
              </Tooltip>
            )}
            {params.value > overstock && (
              <Tooltip title={t('alerts.overstock', { value: overstock })} placement="top">
                <TrendingUpIcon color="warning" />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const stockQuantityRow = params.row;
        const actions = [
          <GridActionsCellItem
            key={1}
            label="Dettaglio"
            icon={<ManageSearchIcon />}
            onClick={() =>
              movementsDetail({
                product: stockQuantityRow.product__code,
                warehouse: stockQuantityRow.warehouse__code
              })
            }
          />
        ];
        return actions;
      },
      filterable: false
    }
  ];

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    productService.getAllBaseInformation().then((res) => {
      setProducts(res);
    });
    warehouseService.getAllBaseInformation().then((res) => {
      setWarehouses(res);
    });
    dispatch(loadingActions.stopLoading());
  }, []);

  const onSubmit = (values) => {
    dispatch(loadingActions.startLoading());
    movementService.stockVariations(values).then((res) => {
      setMovements(res);
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <ButtonGroup size="small">
            <NavLink to="all">
              <Button>{t(`${translationPrefix}.all_quantities`)}</Button>
            </NavLink>
          </ButtonGroup>
        }>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <SectionTitle>{t(`${translationPrefix}.graph`)}</SectionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <ReactFinalForm onSubmit={onSubmit} hideControls>
                <div className="flex justify-end items-center flex-wrap">
                  <div className="w-full px-2 my-2">
                    <Field
                      name="products"
                      component={MultipleAutocompleteAdapter}
                      options={products}
                      label={t('global.products')}
                      type="text"
                    />
                  </div>
                  <div className="w-full md:w-1/4 px-2 my-2">
                    <Field
                      name="warehouse"
                      component={AutocompleteAdapter}
                      options={warehouses}
                      label={t('global.warehouse')}
                      placeholder={t('global.warehouse')}
                      type="text"
                    />
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="w-full md:w-1/4 px-2 my-2">
                      <Field
                        name="date_start"
                        label={t('global.date_start')}
                        component={DatePickerAdapter}
                        type="date"
                      />
                    </div>
                    <div className="w-full md:w-1/4 px-2 my-2">
                      <Field
                        name="date_end"
                        label={t('global.date_end')}
                        component={DatePickerAdapter}
                        type="date"
                      />
                    </div>
                  </LocalizationProvider>
                  <div className="w-full md:w-1/4 px-2 my-2">
                    <Button variant="outlined" type="submit" className="ml-auto">
                      Go
                    </Button>
                  </div>
                </div>
              </ReactFinalForm>
            </div>
            {movements.length > 0 && <StockChart movements={movements} />}
          </AccordionDetails>
        </Accordion>

        <EnhancedDataGrid
          service={stockQuantityService}
          getMethod={stockQuantityService.getTotalsByProduct}
          columns={columns}
          initialOptions={{
            pinnedColumns: {
              left: ['product_code'],
              right: ['total_quantity', 'actions']
            }
          }}
        />
      </TenantPage>
    </>
  );
};

export default StockQuantities;
