import { movementService } from 'services/api';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { ConsumptionsColumns } from 'components/common/enhanced/common-headers/consumptions';
import { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import Box from '@mui/material/Box';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';

interface DateRangeQueryParams {
  timestamp__gte: string;
  timestamp__lte: string;
}

export function ConsumptionDetail() {
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const year = dayjs().year();
  const month = dayjs().month();
  const day = dayjs().subtract(1, 'month').daysInMonth();

  const [range, setRange] = useState<DateRange<Dayjs>>([
    dayjs(`${year - 1}-${month + 1}-01`),
    dayjs(`${year}-${month}-${day}`)
  ]);

  const [params, setParams] = useState<DateRangeQueryParams>({
    timestamp__gte: range[0]?.format('YYYY-MM-DD'),
    timestamp__lte: range[1]?.format('YYYY-MM-DD')
  });

  useEffect(() => {
    setParams({
      timestamp__gte: range[0]?.format('YYYY-MM-DD'),
      timestamp__lte: range[1]?.format('YYYY-MM-DD')
    });
  }, [range]);

  const onChange = (date: DateRange<Dayjs>, keyboardInputValue?: string) => {
    setRange([date[0], date[1]]);
  };

  const columns: GridColDef[] = ConsumptionsColumns(range[0], range[1]);

  return (
    <TenantPage
      title={t('pages.consumption.title')}
      subtitle={t('pages.consumption.subtitle')}
      menuRight={
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            inputFormat="DD/MM/YYYY"
            calendars={2}
            value={range}
            onChange={onChange}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} variant="standard" label={t('global.start')} />

                <Box sx={{ mx: 2 }}>
                  <ArrowForwardIosIcon color={range[1] ? 'primary' : 'disabled'} />
                </Box>

                <TextField {...endProps} variant="standard" label={t('global.end')} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      }>
      <div>
        <EnhancedDataGrid
          initialOptions={{
            pinnedColumns: {
              left: ['product', 'actions']
            }
          }}
          outerParams={params}
          service={movementService}
          refresh={datagridRefresh}
          getMethod={movementService.consumptionDetail}
          columns={columns}
        />
      </div>
    </TenantPage>
  );
}

export default ConsumptionDetail;
