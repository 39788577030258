import { ILocation } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class LocationService extends BaseService<ILocation> {
  constructor() {
    super('locations');
  }

  assignProduct = async (locationId: number, product: number) => {
    const res = await axiosPrivate.patch(`${this.modulePrefix}/${locationId}/assign_product/`, {
      product
    });
    return res.data;
  };
}

export const locationService = new LocationService();
