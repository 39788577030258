import { Dispatch, SetStateAction } from 'react';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { IMovement } from 'interfaces';
import { Checkbox, FormGroup } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import { filterOperators, dateFilterOperators } from '../../../../constants';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { movementService } from 'services/api';
import { createSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';

export function MovementsColumns(
  types: string[],
  canValidate = false,
  canEdit = false,
  detail = false,
  setMovement?: Dispatch<SetStateAction<number>>
): GridColDef[] {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const translationPrefix = 'entities.movement';

  const movementsDetail = (params) => {
    const filteredParams = _.omitBy(params, _.isEmpty);
    navigate({
      pathname: '/tenant/movements/detail/',
      search: `${createSearchParams(filteredParams)}`
    });
  };

  const columns: GridColumns | any[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        {
          const movementRow = params.row;
          if (detail) {
            if (movementRow.id) {
              return [
                <GridActionsCellItem
                  key={1}
                  icon={<DeleteIcon />}
                  onClick={() => {
                    setMovement(+params.row.id);
                  }}
                  label="Elimina"
                />
              ];
            } else {
              return [];
            }
          } else {
            return [
              <GridActionsCellItem
                key={2}
                label="Dettaglio"
                icon={<ManageSearchIcon />}
                onClick={() =>
                  movementsDetail({
                    product: movementRow.product,
                    warehouse: movementRow.warehouse,
                    type: movementRow.type,
                    created_by: movementRow.created_by,
                    product_batch: movementRow.product_batch,
                    supplier: movementRow.supplier,
                    purchase_order: movementRow.purchase_order,
                    timestamp: movementRow.timestamp
                  })
                }
              />
            ];
          }
        }
      },
      filterable: false
    },
    {
      field: 'moved_quantity',
      headerName: t(`${translationPrefix}.moved_quantity`),
      filterOperators,
      renderCell: (params: GridRenderCellParams<string>) => {
        const movement = params.row as IMovement;
        return (
          <>
            {movement.direction === 1 ? (
              <ArrowDownwardIcon color="success" />
            ) : (
              <ArrowUpward color="warning" />
            )}{' '}
            {movement.moved_quantity}
          </>
        );
      }
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      dateFilterOperators,
      type: 'date',
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'amount',
      headerName: t(`${translationPrefix}.price`),
      dateFilterOperators,
      type: 'date',
      width: 250,
      renderCell: currencyCell
    },
    {
      field: 'category',
      headerName: t(`entities.product.category`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      dateFilterOperators,
      type: 'date',
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      dateFilterOperators,
      type: 'date',
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'type',
      headerName: t(`${translationPrefix}.type`),
      type: 'singleSelect',
      valueOptions: types,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'order_code',
      headerName: t(`entities.movement.order_code`),
      filterOperators,
      editable: canEdit,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'order_date',
      headerName: t(`${translationPrefix}.order_date`),
      editable: canEdit,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'transport_document',
      valueGetter: (params) => params.row.transport_document_ref,
      headerName: t(`${translationPrefix}.transport_document`),
      filterOperators,
      width: 200,
      editable: canEdit,
      renderCell: baseCell
    },
    {
      field: 'transport_document_date',
      headerName: t(`${translationPrefix}.transport_document_date`),
      width: 200,
      type: 'date',
      renderCell: baseCell
    },
    {
      field: 'created_by',
      headerName: t(`${translationPrefix}.created_by`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    }
  ];

  if (canValidate) {
    columns.unshift({
      field: 'approved',
      headerName: t(`${translationPrefix}.approved`),
      renderCell(params) {
        const movement = params.row as IMovement;
        const handleApprove = () => {
          movementService
            .update(movement.id, { approved: !movement.approved })
            .then(() => (movement.approved = !movement.approved));
        };

        return (
          <FormGroup>
            <Checkbox checked={movement.approved} onChange={handleApprove} />
          </FormGroup>
        );
      }
    });
  }
  return columns;
}
