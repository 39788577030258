import { movementReasonService, movementService } from 'services/api';
import { Button, ButtonGroup } from '@mui/material';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid';
import { IOption } from 'interfaces';

import { loadingActions } from 'features/loadingSlice';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsableColumns from 'components/common/CollapsableColumns';
import TenantPage from 'components/common/TenantPage';
import { MovementsColumns } from 'components/common/enhanced/common-headers/movements';
import toast from 'features/toast';
import { printLabels } from 'services/zebra/zebraUtils';
import ManualMovement from '../ManualMovement';

export const Movements = () => {
  const translationPrefix = 'pages.movements';
  const datagridRefresh = useRef(null);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);
  const [insert, setInsert] = useState(false);
  const [numMovementPending, setNumMovementPending] = useState(0);

  const columns: GridColDef[] = MovementsColumns(movementReasons.map((reason) => reason.code));

  useEffect(() => {
    movementService.getUnapproved(1).then((res) => {
      setNumMovementPending(res.count);
    });
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
  }, []);

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    if (values['print_labels']) {
      printLabels(
        values.product,
        values.product_batch,
        values.expiration_date,
        values.moved_quantity
      );
    }
    movementService
      .moveProduct(values)
      .then((res) => {
        console.log(res);
        if ('warnings' in res) {
          res.warnings.map((warning) => {
            toast.warning(warning.type);
          });
        }
        datagridRefresh.current();
        toast.success('Movimentazione avvenuta con successo');
      })
      .catch(() => {
        toast.error('Movimentazione fallita');
      });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div>
          <ButtonGroup size="small" className="mr-2">
            <Button size="small" color={'primary'}>
              <NavLink to="uninvoiced">
                <>{t(`${translationPrefix}.go-to-uninvoiced`)}</>
              </NavLink>
            </Button>
            <Button size="small" color={numMovementPending > 0 ? 'error' : 'success'}>
              <NavLink to="unapproved">
                <>
                  {t(`${translationPrefix}.to_be_approved`)} ({numMovementPending})
                </>
              </NavLink>
            </Button>
            <Button size="small" variant="outlined">
              <NavLink to="reasons">{t(`${translationPrefix}.reasons`)}</NavLink>
            </Button>
          </ButtonGroup>
          <ButtonGroup size="small">
            <Button onClick={() => setInsert(!insert)}>{t(`${translationPrefix}.new`)}</Button>
          </ButtonGroup>
        </div>
      }>
      <CollapsableColumns
        size="w-1/3"
        expand={insert}
        contentLeft={
          <EnhancedDataGrid
            columns={columns}
            service={movementService}
            refresh={datagridRefresh}
            // getMethod={movementService.getAggregated}
            initialOptions={{
              pinnedColumns: {
                left: ['direction', 'moved_quantity'],
                right: ['actions']
              }
            }}
          />
        }
        contentRight={<ManualMovement onSubmit={onSubmit} />}
      />
    </TenantPage>
  );
};

export default Movements;
