import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'components/helpers/ScrollToTop';
import './App.css';
import AppRoutes from './AppRoutes';
import Spinner from './components/common/Spinner';
import { createTheme } from '@mui/material/styles';

function App() {
  const suiteTheme = createTheme({
    palette: {
      primary: {
        main: '#3f7af9'
      },
      secondary: {
        main: '#fe7f2d',
        contrastText: '#fff'
      },
      success: {
        main: '#5BC473'
      },
      error: {
        main: '#ED779E'
      },
      warning: {
        main: '#8aa5d8'
      },
      info: {
        main: '#8aa5d8'
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.15
    }
  });

  return (
    <ThemeProvider theme={suiteTheme}>
      <div className="App">
        <Spinner />
        <ScrollToTop />
        <AppRoutes />
      </div>
    </ThemeProvider>
  );
}

export default App;
