import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IOption, IProduct, IStatusOption } from 'interfaces';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import SectionTitle from 'components/common/SectionTitle';
import { useEffect, useState } from 'react';
import { categoryService, productService, typeService } from 'services/api';

interface ProductFormParams {
  product: IProduct;
  onSubmit: any;
}
function ProductForm(params: ProductFormParams) {
  const translationPrefix = 'entities.product';
  const { t } = useTranslation();

  const [categories, setCategories] = useState<IOption[]>([]);
  const [types, setTypes] = useState<IOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);
  // const [ivdrOptions, setIvdrOptions] = useState<IStatusOption[]>([]);

  useEffect(() => {
    categoryService.getAllBaseInformation().then(setCategories);
    typeService.getAllBaseInformation().then(setTypes);
    productService.options().then((res) => {
      setStatusOptions(res.actions.POST.status.choices);
      // setIvdrOptions(res.actions.POST.ivdr_class.choices);
    });
  }, []);

  const { product, onSubmit } = params;

  const _submit = (values) => {
    const _values = { ...values, weight: +values['weight'], dimensions: +values['dimensions'] };
    onSubmit(_values);
  };

  return (
    <div>
      {product && (
        <ReactFinalForm onSubmit={_submit} edit={!!product.id}>
          <div className="flex flex-wrap">
            <div className="w-full p-2">
              <SectionTitle>{t('global.base-info')}</SectionTitle>
            </div>
            <div className="p-2 w-full md:w-1/4">
              <Field
                name="code"
                label={t(`global.code`)}
                initialValue={product.code}
                component={TextFieldAdapter}
                placeholder={t('global.code')}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/4">
              <Field
                name="status"
                label={t(`entities.product.status`)}
                initValue={
                  product.status
                    ? {
                        ...product.status,
                        display_name: t(
                          `${translationPrefix}.status_options.${product.status.display_name}`
                        )
                      }
                    : {
                        value: 1,
                        display_name: t(`${translationPrefix}.status_options.ACTIVE`)
                      }
                }
                defaultValue={product.status ? product.status.value : 1}
                component={AutocompleteAdapter}
                options={statusOptions}
                placeholder="Status"
                type="text"
                optionLabel="display_name"
                optionValue="value"
              />
            </div>
            <div className="p-2 w-full md:w-1/2">
              <Field
                name="description"
                label={t(`global.description`)}
                initialValue={product.description}
                component={TextFieldAdapter}
                placeholder={t('global.description')}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/2">
              <Field
                name="category"
                label={t(`global.category`)}
                initValue={product.category}
                component={AutocompleteAdapter}
                options={categories}
                placeholder="Category"
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-1/2">
              <Field
                name="type"
                label={t(`global.type`)}
                initValue={product.type}
                component={AutocompleteAdapter}
                options={types}
                placeholder="Type"
                type="text"
              />
            </div>
            <div className="p-2 w-full">
              <SectionTitle>{t('entities.product.packaging')}</SectionTitle>
            </div>
            <div className="p-2 w-full md:w-3/12">
              <Field
                name="uom"
                label={t(`entities.product.uom`)}
                component={TextFieldAdapter}
                initialValue={product.uom}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-2/12">
              <Field
                name="packaging"
                label={t(`entities.product.packaging`)}
                initialValue={product.packaging}
                component={TextFieldAdapter}
                type="text"
              />
            </div>
            <div className="p-2 w-full md:w-3/12">
              <Field
                name="units_per_package"
                label={t(`entities.product.units_per_package`)}
                initialValue={product.units_per_package}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full md:w-2/12">
              <Field
                name="dimensions"
                label={t(`entities.product.dimensions`)}
                initialValue={product.dimensions}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full md:w-2/12">
              <Field
                name="weight"
                label={t(`entities.product.weight`)}
                initialValue={product.weight}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full">
              <SectionTitle>{t('global.thresholds')}</SectionTitle>
            </div>
            <div className="p-2 w-full md:w-1/5">
              <Field
                name="restock_threshold"
                label={t(`entities.product.restock_threshold`)}
                component={TextFieldAdapter}
                initialValue={product.restock_threshold}
                placeholder="Restock threshold"
                type="number"
              />
            </div>
            <div className="p-2 w-full md:w-1/5">
              <Field
                name="understock_threshold"
                label={t(`entities.product.understock_threshold`)}
                initialValue={product.understock_threshold}
                component={TextFieldAdapter}
                placeholder="Understock threshold"
                type="number"
              />
            </div>
            <div className="p-2 w-full md:w-1/5">
              <Field
                name="overstock_threshold"
                label={t(`entities.product.overstock_threshold`)}
                initialValue={product.overstock_threshold}
                component={TextFieldAdapter}
                placeholder="Overstock threshold"
                type="number"
              />
            </div>
            <div className="p-2 w-full md:w-1/5">
              <Field
                name="expiration_alert_threshold"
                label={t(`entities.product.expiration_alert_threshold`)}
                initialValue={product.expiration_alert_threshold}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full md:w-1/5">
              <Field
                name="preservation_temperature"
                label={t(`entities.product.preservation_temperature`)}
                initialValue={product.preservation_temperature}
                component={TextFieldAdapter}
                type="number"
              />
            </div>
            <div className="p-2 w-full">
              <Field
                name="notes"
                label={t(`global.notes`)}
                initialValue={product.notes}
                component={TextFieldAdapter}
              />
            </div>
            <div className="p-2 w-full">
              <Field
                name="order_notes"
                label={t(`entities.product.order_notes`)}
                initialValue={product.order_notes}
                component={TextFieldAdapter}
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default ProductForm;
