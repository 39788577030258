import {
  contractLineService,
  movementReasonService,
  orderService,
  productService,
  stockQuantityService,
  tenantSettingsService,
  transportDocumentService,
  warehouseService
} from 'services/api';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { Field } from 'react-final-form';
import { IContractLine, IOption, ITenantSettings } from 'interfaces';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import dayjs from 'dayjs';
import { Alert } from '@mui/material';
import { useAppSelector } from 'app/store';

interface ManualMovementProps {
  onSubmit: any;
}

function ManualMovement(props: ManualMovementProps) {
  const { onSubmit } = props;
  const { t } = useTranslation();

  const defaultWarehouse = null;
  // const defaultWarehouse = useAppSelector((state) => state.auth.warehouse);
  // useEffect(() => {
  //   setWarehouse({ code: defaultWarehouse, id: 0 });
  // }, [defaultWarehouse]);

  const [products, setProducts] = useState<IOption[]>([]);
  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const [contractLines, setContractLines] = useState<IContractLine[]>([]);
  const [warehouse, setWarehouse] = useState<IOption | null>(null);
  const [product, setProduct] = useState<IOption | null>(null);
  const [type, setType] = useState<IOption | null>(null);
  const [batches, setBatches] = useState<IOption[]>([]);
  const [orders, setOrders] = useState<IOption[]>([]);
  const [order, setOrder] = useState<IOption | null>(null);
  const [tenantSettings, setTenantSettings] = useState<ITenantSettings>();

  const [transportDocuments, setTransportDocuments] = useState<IOption[]>([]);
  const [expiration, setExpiration] = useState<Date>();

  useEffect(() => {
    tenantSettingsService.get(1).then(setTenantSettings);
    warehouseService.getAllBaseInformation().then(setWarehouses);
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
    productService.getAllBaseInformation().then(setProducts);
  }, []);

  useEffect(() => {
    if (type && type['direction'] === 'OUT' && product && warehouse) {
      const params = {
        product: product?.code ?? '',
        warehouse: warehouse?.code ?? ''
      };
      stockQuantityService.getAllPaginated(1000, 0, params).then((res) => {
        setBatches(
          res.results.map((value) => {
            return {
              id: value.product_batch,
              code: `${value.product_batch}; Scad: ${value.expiration_date}; Qty: ${value.quantity}`
            };
          })
        );
      });
    } else {
      setBatches([]);
    }
    if (type && !type['order_independent'] && product && warehouse) {
      const params = {
        product: product?.id ?? '',
        warehouse: warehouse?.code ?? '',
        status: 1
      };
      orderService.openOrders(1000, 0, params).then((res) => {
        setOrders(
          res.results.map((value) => {
            return {
              id: value.id,
              code: value.ref
            };
          })
        );
      });
    } else if (type && type['order_independent'] && product && warehouse) {
      const params = {
        product: product?.id ?? '',
        laboratory: warehouse['laboratory'] ?? ''
      };
      contractLineService.getAllPaginated(100, 0, params).then((res) => {
        setContractLines(res.results);
      });
    } else {
      setOrders([]);
    }
  }, [type, product, warehouse]);

  useEffect(() => {
    if (order) {
      transportDocumentService.getAllBaseInformation({ order: order.id }).then((res) => {
        const x = res.map((ddt) => ({ id: ddt.id, code: ddt.ref }));
        setTransportDocuments(x);
      });
    }
  }, [order]);

  const submitForm = (values) => {
    const movement = { ...values };
    if (warehouse && warehouse.code) {
      movement.warehouse = warehouse.code;
    }
    if (contractLines.length === 1) {
      movement.unit_price = contractLines[0].unit_price;
    }
    onSubmit(movement);
  };
  return (
    <ReactFinalForm onSubmit={submitForm}>
      <div className="flex flex-wrap my-4">
        {tenantSettings &&
          expiration &&
          tenantSettings.days_before_expiration_alert &&
          dayjs(expiration).diff(dayjs(), 'days') <=
            tenantSettings.days_before_expiration_alert && (
            <Alert severity="error" className="w-full">
              {t(`alerts.product-close-to-expiration`)}
            </Alert>
          )}
        {!defaultWarehouse && (
          <div className="w-full my-2">
            <Field
              name="warehouse"
              component={AutocompleteAdapter}
              options={warehouses}
              label={t('global.warehouse')}
              type="text"
              optionValue={'code'}
              setValue={setWarehouse}
              validate={required}
            />
          </div>
        )}
        <div className="w-full my-2">
          <Field
            name="product"
            label={t('global.product')}
            component={AutocompleteAdapter}
            options={products}
            type="text"
            optionValue={'code'}
            optionLabel={'description'}
            setValue={setProduct}
            validate={required}
          />
        </div>
        <div className="w-full md:w-1/2 my-2 md:pr-2">
          <Field
            name="movement_type"
            label={t('entities.movement.type')}
            component={AutocompleteAdapter}
            options={movementReasons}
            optionValue="code"
            type="text"
            setValue={setType}
            validate={required}
          />
        </div>
        <div className="w-full md:w-1/2 my-2 md:pl-2">
          <Field
            name="moved_quantity"
            label={t('entities.movement.moved_quantity')}
            component={TextFieldAdapter}
            type="number"
            validate={required}
          />
        </div>
        {type &&
          type['direction'] === 'IN' &&
          type['order_independent'] &&
          contractLines?.length !== 1 && (
            <div className="w-full my-2">
              <Field
                name="contract_line"
                label={t('global.contract')}
                component={AutocompleteAdapter}
                options={contractLines}
                type="text"
                optionValue={'id'}
                optionLabel={'contract_header'}
                validate={required}
              />
            </div>
          )}
        {type && type['direction'] === 'OUT' ? (
          <div className="w-full">
            <Field
              name="product_batch"
              label={t('entities.movement.product_batch')}
              component={AutocompleteAdapter}
              options={batches}
              type="text"
              validate={required}
            />
          </div>
        ) : (
          <div className="w-full md:w-1/2 my-2 md:pr-2 mt-2">
            <Field
              name="product_batch"
              label={t('entities.movement.product_batch')}
              component={TextFieldAdapter}
              type="text"
              validate={required}
            />
          </div>
        )}
        {type && type['direction'] === 'IN' ? (
          <>
            <div className="w-full md:w-1/2 my-2 md:pl-2">
              <Field
                name="expiration_date"
                component={DatePickerAdapter}
                label={t('entities.movement.expiration_date')}
                setValue={setExpiration}
                type="date"
                validate={required}
              />
            </div>
            {!type['order_independent'] ? (
              <>
                <div className="w-full my-2">
                  <Field
                    name="order_code"
                    component={AutocompleteAdapter}
                    options={orders}
                    label={t('entities.movement.order_code')}
                    type="text"
                    optionValue={'code'}
                    setValue={setOrder}
                    validate={required}
                  />
                </div>
              </>
            ) : null}

            {type && type['direction'] === 'IN' && (type['ddt'] || !type['order_independent']) && (
              <>
                <div className="w-full md:w-1/2 my-2 md:pr-2">
                  <Field
                    freeSolo={true}
                    name="transport_document"
                    component={AutocompleteAdapter}
                    options={transportDocuments}
                    label={t('entities.movement.transport_document')}
                    type="text"
                    optionValue={'code'}
                  />
                </div>
                <div className="w-full md:w-1/2 my-2 md:pl-2">
                  <Field
                    name="transport_document_date"
                    component={DatePickerAdapter}
                    label={t('entities.movement.transport_document_date')}
                    type="date"
                  />
                </div>
              </>
            )}

            <div className="w-full my-2">
              <Field
                name="notes"
                component={TextFieldAdapter}
                label={t('global.notes')}
                type="text"
              />
            </div>
            <div className="w-full">
              <Field
                name="print_labels"
                component={CheckboxAdapter}
                label={t(`pages.movements.print_labels`)}
                type="checkbox"
              />
            </div>
          </>
        ) : null}
      </div>
    </ReactFinalForm>
  );
}

export default ManualMovement;
