import { ISellingOrder } from 'components/selling/models';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class SellingOrderService extends BaseService<ISellingOrder> {
  constructor() {
    super('selling_order');
  }

  forceSync = async () => {
    axiosPrivate.post(`${this.modulePrefix}/sync`);
  };
}

export const sellingOrderService = new SellingOrderService();
