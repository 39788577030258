import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { filterOperators } from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { baseCell } from '../cells/base-cell';

export function TypesColumns(): GridColDef[] {
  const { t } = useTranslation();
  return [
    {
      field: 'code',
      headerName: t(`global.code`),
      renderCell: (params: GridRenderCellParams) => {
        const type = params.row;
        return (
          <div>
            <Link to={`${type.id}`}>
              <b className="text-secondary-500">{type.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      editable: true,
      width: 500,
      renderCell: baseCell
    }
  ];
}
