import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DataGridCell from 'components/common/DataGridCell';
import { baseCell } from '../cells/base-cell';

export function OrderDetailColumns(): GridColDef[] {
  const { t } = useTranslation();

  return [
    {
      field: 'id',
      headerName: t(`global.id`),
      width: 150,
      type: 'number',
      filterable: false,
      renderCell: baseCell
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 250,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={`${params.value['code']}`} />
      )
    },
    {
      field: 'product_description',
      headerName: t(`global.product_description`),
      width: 250,
      filterable: false,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.row.product['description']} />
      )
    },
    {
      field: 'is_periodic',
      headerName: t(`entities.order_line.is_periodic`),
      width: 100,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.row.is_periodic ? t('global.yes') : ''} />
      )
    },
    {
      field: 'ref',
      headerName: t(`entities.order_line.ref`),
      width: 100,
      renderCell: baseCell
    },
    {
      field: 'requested_shipment_date',
      type: 'date',
      headerName: t(`entities.order_line.requested_shipment_date`),
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'remaining_quantity',
      type: 'number',
      headerName: t('entities.order_line.remaining_quantity'),
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t('global.status'),
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        return (
          <div>
            <span className={value === 'PENDING' ? 'text-orange-500' : 'text-green-500'}>
              {t(`entities.order_line.${value}`)}
            </span>
          </div>
        );
      }
    },
    {
      field: 'notes',
      headerName: t('global.notes'),
      width: 300,
      renderCell: baseCell
    }
  ];
}
