import { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TenantMenu from '../components/TenantMenu';
import { classNames } from '../helpers/classNames';
import { logout } from '../features';
import { useAppDispatch, useAppSelector } from '../app/store';
import { deepOrange } from '@mui/material/colors';
import CachedIcon from '@mui/icons-material/Cached';
import { Badge, IconButton, List, ListItem, ListItemButton, Popover, Tooltip } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { notificationService } from 'services/api';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { WarehouseSelector } from 'components/common';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Tenant = () => {
  const user = useAppSelector((state) => state.auth.user);
  const groups = useAppSelector((state) => state.auth.groups);

  const themeColor = 'primary';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const checkForNotifications = () => {
    notificationService.getUnread().then((res) => {
      setUnreadNotifications(res.results);
      setUnreadNotificationsCount(res.count);
    });
  };

  useEffect(() => {
    checkForNotifications();
    const intervalId = setInterval(checkForNotifications, 15 * 1000000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const userNavigation = [
    {
      name: 'Your Profile',
      href: '#',
      handle: () => {
        console.log('not implemented');
      }
    },
    {
      name: 'Settings',
      href: '#',
      handle: () => {
        navigate('/tenant/settings');
      }
    },
    { name: 'Sign out', href: '#', handle: () => dispatch(logout()) }
  ];
  return (
    <>
      <div className="bg-stone-700"></div>
      <div className="bg-primary-800"></div>
      <div>
        <div className="z-10 flex-shrink-0 flex h-12 bg-white shadow">
          <div className="flex items-center flex-shrink-0 px-8">
            <div className="mr-4">
              <img className="h-8 w-auto" src="/logo-extended.png" alt="Workflow" />
            </div>
            <div>
              <Tooltip title="refresh">
                <button
                  type="button"
                  onClick={() => location.reload()}
                  className="bg-white p-1 rounded-full text-secondary-600 hover:text-secondary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <span className="sr-only">View notifications</span>
                  <CachedIcon className="h-6 w-6" />
                </button>
              </Tooltip>
            </div>
          </div>
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-8 flex justify-end">
            <div>{/* <WarehouseSelector /> */}</div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <div className="px-2 mt-2">
                    <Badge badgeContent={unreadNotificationsCount} color="primary">
                      <IconButton
                        {...bindTrigger(popupState)}
                        className="h-7 w-6 relative"
                        aria-hidden="true">
                        <NotificationsIcon aria-hidden="true" />
                      </IconButton>
                    </Badge>
                    <Popover
                      {...bindPopover(popupState)}
                      // sx={{ maxWidth: '520px' }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      PaperProps={{
                        style: { maxWidth: '400px' }
                      }}>
                      <List>
                        {unreadNotifications &&
                          unreadNotifications.map((notification) => (
                            <ListItem disablePadding key={notification.notification.id}>
                              <ListItemButton>
                                <NavLink to={`/tenant/notifications?id=${notification.id}`}>
                                  <ListItemText
                                    className={classNames(`text-left truncate`)}
                                    primary={notification.notification.message}
                                  />
                                </NavLink>
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </Popover>
                  </div>
                )}
              </PopupState>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 z-40 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <Avatar className="bg-green-500" sx={{ bgcolor: deepOrange[500] }}>
                      {user[0].toLocaleUpperCase()}
                    </Avatar>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={item.handle}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}>
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-tranprimary-x-full"
                enterTo="tranprimary-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="tranprimary-x-0"
                leaveTo="-tranprimary-x-full">
                <Dialog.Panel
                  className={`relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-${themeColor}-700`}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://aidia.it/img/AIDIA.png"
                      alt="Workflow"
                    />
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <TenantMenu collapsed={false} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="flex">
          {/* Static sidebar for desktop */}
          <div className="transition-all hidden md:flex md:flex-col md:min-h-screen md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div
              className={`transition-all flex flex-col flex-grow bg-${themeColor}-800 overflow-y-auto`}>
              <div className="flex-1 flex flex-col">
                <TenantMenu collapsed={sidebarCollapsed} />
              </div>
              <IconButton onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                {sidebarCollapsed ? (
                  <ArrowRightIcon color="info" />
                ) : (
                  <ArrowLeftIcon color="info" />
                )}
              </IconButton>
            </div>
          </div>
          <div className="flex flex-col flex-1">
            <main>
              <div className="mx-auto sm:px-6 sm:pb-3 lg:px-0 lg:pb-4">
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tenant;
