import AllInboxIcon from '@mui/icons-material/AllInbox';
import BiotechIcon from '@mui/icons-material/Biotech';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Menu from './common/Menu';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewListIcon from '@mui/icons-material/ViewList';
import WarehouseIcon from '@mui/icons-material/Warehouse';

const navigation = [];
// navigation.push({ name: 'home', href: 'home', icon: DashboardIcon });

navigation.push({
  name: 'inventory',
  base_url: 'inventory',
  icon: HandshakeIcon,
  items: [
    {
      name: 'contracts',
      href: 'contracts',
      icon: HandshakeIcon
    },
    { name: 'suppliers', href: 'suppliers', icon: StorefrontIcon },
    { name: 'products', href: 'products', icon: BiotechIcon },
    // {
    //   name: 'equipments',
    //   href: 'equipments',
    //   icon: PrecisionManufacturingIcon
    // },
    { name: 'types', href: 'types', icon: CategoryIcon },
    { name: 'categories', href: 'categories', icon: CategoryIcon },
    {
      name: 'skus',
      href: 'skus',
      icon: QrCode2Icon
    }
  ]
});

// Movements
navigation.push({
  name: 'movements',
  base_url: 'movements',
  icon: CompareArrowsIcon,
  items: [
    { name: 'movements', href: '', icon: CompareArrowsIcon }
    // { name: 'stock', href: 'stock', icon: ShowChartIcon },
    // { name: 'consumption', href: 'consumption', icon: LeaderboardIcon }
  ]
});

// // Orders
navigation.push({
  name: 'orders',
  base_url: 'inventory/buying',
  icon: LocalShippingIcon,
  items: [
    { name: 'purchase-requests', href: 'purchase_requests', icon: ViewListIcon },
    {
      name: 'orders',
      href: 'orders',
      icon: LocalShippingIcon
    },
    {
      name: 'ddt',
      href: 'invoices/ddt',
      icon: ListAltIcon
    }
  ]
});
// Reporting
navigation.push({
  name: 'reporting',
  base_url: 'reporting',
  icon: SummarizeIcon,
  items: [
    {
      name: 'reporting',
      href: 'reporting',
      icon: SummarizeIcon
    }
    // {
    //   name: 'costs',
    //   href: 'cost-analysis/costs',
    //   icon: PointOfSaleIcon
    // },
    // {
    //   name: 'workstations',
    //   href: 'cost-analysis/workstations',
    //   icon: WarehouseIcon
    // }
  ]
});

// Selling

navigation.push({
  name: 'selling',
  base_url: 'inventory/selling',
  icon: StorefrontIcon,
  items: [
    {
      name: 'orders',
      href: 'orders',
      icon: ShoppingBasketIcon
    },
    {
      name: 'shipping',
      href: 'shipping',
      icon: AllInboxIcon
    },
    {
      name: 'couriers',
      href: 'couriers',
      icon: LocalShippingIcon
    },
    {
      name: 'packaging',
      href: 'packaging',
      icon: MoveToInboxIcon
    }
  ]
});
// Referting

navigation.push({
  name: 'settings',
  base_url: 'settings',
  icon: PeopleAltIcon,
  items: [
    {
      name: 'users',
      href: 'users',
      icon: PeopleAltIcon
    },
    {
      name: 'structure',
      href: 'topology',
      icon: BusinessIcon
    },
    {
      name: 'settings',
      href: 'settings',
      icon: SettingsIcon
    }
  ]
});

interface TenantMenuProps {
  collapsed: boolean;
}

export const TenantMenu = (props: TenantMenuProps) => {
  const { collapsed } = props;

  return Menu(navigation, collapsed);
};

export default TenantMenu;
