import { IWarehouse } from 'interfaces';
import { BaseService } from './BaseService';

class WarehouseService extends BaseService<IWarehouse> {
  constructor() {
    super('warehouses');
  }
}

export const warehouseService = new WarehouseService();
