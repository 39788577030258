import { INotification } from 'interfaces';
import { useEffect, useState } from 'react';
import { notificationService } from 'services/api';
import { Alert, Button, Skeleton, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import { useTranslation } from 'react-i18next';

export function NotificationWidget() {
  const { t } = useTranslation();
  const MAX_CHARACTERS = 150;
  const [notifications, setNotifications] = useState<INotification[]>();

  useEffect(() => {
    notificationService.getAllPaginated(3, 0).then((res) => {
      setNotifications(res.results);
    });
  }, []);

  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
        <MarkunreadMailboxIcon />
        {t('dashboard.notifications.title')}
      </Typography>
      {!notifications && (
        <>
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
          <br />
          <Skeleton variant="rectangular" height={32} />
        </>
      )}
      {notifications &&
        notifications.map((n, idx) => (
          <Alert key={n.id} className="mt-2" severity="info">
            <p className="truncate">
              {n.notification.message.slice(0, MAX_CHARACTERS) +
                (n.notification.message.length > MAX_CHARACTERS ? `...` : ``)}
            </p>
          </Alert>
        ))}
      <div className="flex justify-center mt-2">
        <NavLink to="/tenant/notifications">
          <Button variant="outlined" size="small">
            Vedi tutto
          </Button>
        </NavLink>
      </div>
    </div>
  );
}

export default NotificationWidget;
