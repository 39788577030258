import { IStockQuantity } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class StockQuantityService extends BaseService<IStockQuantity> {
  constructor() {
    super('stock-quantities');
  }

  getTotalsByProduct = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/total_by_product/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };
}

export const stockQuantityService = new StockQuantityService();
