import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import StyledDropzone from '../StyledDropzone';
import PublishIcon from '@mui/icons-material/Publish';
import { useTranslation } from 'react-i18next';

interface ImportFileUploaderProps {
  service: any;
  onUploadSuccess?: any;
}

function ImportFileUploader(props: ImportFileUploaderProps) {
  const { t } = useTranslation();
  const { service, onUploadSuccess } = props;

  const [openDropzone, setOpenDropzone] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const attachFile = () => {
    setOpenDropzone(true);
  };

  const handleUpload = () => {
    if (fileToUpload) {
      service.importFromFile(fileToUpload).then(() => {
        if (onUploadSuccess) onUploadSuccess();
        enqueueSnackbar('Import started.', { variant: 'success' });
        setOpenDropzone(false);
      });
    }
  };

  const handleClose = () => {
    setFileToUpload(null);
    setOpenDropzone(false);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      enqueueSnackbar('The file you are trying to upload exceeds 20 MiB.', { variant: 'error' });
      return;
    }

    const file = acceptedFiles[0];
    if (file) {
      setFileToUpload(file);
    }
  };

  const importButtonProps: ButtonProps = {
    color: 'success',
    size: 'small',
    startIcon: <PublishIcon />
  };

  return (
    <>
      <Button {...importButtonProps} onClick={attachFile}>
        {t('data-grid.import')}
      </Button>
      <Dialog open={openDropzone}>
        <DialogTitle>{t(`import-modal.title`)}</DialogTitle>
        <DialogContent>
          <StyledDropzone onDrop={handleDrop} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('actions.cancel')}</Button>
          <Button onClick={handleUpload} disabled={!fileToUpload}>
            {t('actions.upload')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImportFileUploader;
